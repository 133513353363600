<template>
  <div class="page">
    <a-form class="ctrl" layout="inline">
      <a-form-item v-if="[1, '1', 3, '3'].includes($store.state.user.group_id)" label="权限">
        <a-select show-search placeholder="请选择权限" option-filter-prop="children" v-model="options.group_id"
          style="width: 150px;" @change="getList">
          <a-select-option :value="0">
            全部
          </a-select-option>
          <a-select-option :value="3">
            内勤
          </a-select-option>
          <a-select-option :value="2">
            代理
          </a-select-option>
          <a-select-option :value="4">
            二级代理
          </a-select-option>
        </a-select>
      </a-form-item>

      <a-form-item label="姓名">
        <a-input style="width: 200px" placeholder="请输入姓名" v-model="options.name"></a-input>
      </a-form-item>
      <a-form-item label="手机号">
        <a-input style="width: 200px" placeholder="请输入手机号" v-model="options.tel"></a-input>
      </a-form-item>

      <a-form-item>
        <a-button type="primary" @click="search">搜索</a-button>
      </a-form-item>
    </a-form>

    <div class="statistics">共{{ page.amount }}个</div>
    <a-table class="sec" size="small" :rowKey="(record) => record.id" bordered :columns="columns" :data-source="list"
      :pagination="false">

      <template slot="xuhao" slot-scope="text, record">
        {{ list.indexOf(record) + 1 }}
      </template>

      <template slot="quanxian" slot-scope="text, record">
        <div v-if="record.group_id == 2">代理</div>
        <div  v-if="record.group_id == 3">内勤</div>
        <div  v-if="record.group_id == 4">二级代理</div>
      </template>


      <template slot="action" slot-scope="text, record">

        <a-button style="margin: 5px" size="small" type="" @click="goto('/daili/detail?id=' + record.id)">详情</a-button>
        <a-button size="small" type="primary" @click="goto('/daili/update?id=' + record.id)">编辑</a-button>

        <a-popconfirm title="确定要删除吗" v-if="record.status == 1" @confirm="updateStatus(record.id, 0)" okText="确定" cancelText="取消">
          <a-button style="margin: 5px" size="small" type="danger">删除</a-button>
        </a-popconfirm>

        <a-button style="margin: 5px" size="small" type="" @click="gotoDingdan(record.id)">查询订单</a-button>
      </template>
    </a-table>

    <a-pagination class="pagination" showQuickJumper v-model="page.now_page" :defaultPageSize="50" :total="page.amount"
      @change="getList" />
  </div>
</template>

<style lang='scss' scoped>
</style>

<script>

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      // scopedSlots: {
      //   customRender: "xuhao",
      // },
    },
    {
      title: "姓名",
      dataIndex: "name",
    },
    {
      title: "权限",
      scopedSlots: {
        customRender: "quanxian",
      },
    },
    {
      title: "账号",
      dataIndex: "username",
    },
    {
      title: "手机号",
      dataIndex: "tel",
    },
    {
      title: "添加时间",
      dataIndex: "created_at",
    },
    {
      title: "操作",
      scopedSlots: {
        customRender: "action",
      },
    },
  ];

  export default {
    name: 'dailiList',
    data() {
      return {
        columns,
        list: [],
        options: {
          name: '',
          tel: '',
          group_id: 0
        },
        page: {
          now_page: 1,
          page_size: 50,
          amount: 0,
        },
      };
    },
    activated() {
      this.getList();
    },
    methods: {
      search() {
        this.page.now_page = 1;
        this.getList();
      },

      async getList() {
        const options = { ...this.options };
        options.page = this.page.now_page;
        const r = await this.$axios.post("/user/getList", options);

        if (r.status != 1) return;
        this.list = r.list;
        this.page.amount = r.amount;
      },

      goto(url) {
             // 打开新窗口
            // let routeData = this.$router.resolve({ path: url });
            // window.open(routeData.href, '_blank');
                // this.$router.push({
                //     path:'/xieyi?idtype='+ num
                // });

            this.$router.push(url);
        },

        gotoDingdan(id) {
          this.$router.push('/order/list?daili_id=' + id)
        },

      async updateStatus(id, status) {
        const r = await this.$axios.post("/user/updateStatus", {
          id,
          status,
        });

        if (r.status == 1) this.getList();
      }
    },
  };
</script>