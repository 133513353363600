<template>
    <div class="component-wrap">
        <a-form class="form sec" :form="form" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
            <a-row>
                <a-col :span="12">
                    <a-form-item label="保险金额">
                        <a-input v-decorator="['fangan', { rules: [{ required: true, message: '请填写方案' }] }]"
                            :disabled="isEdit ? false : true" addon-after="万元">
                        </a-input>
                        <div>请用英文“,”分割各个方案</div>
                    </a-form-item>
                    <a-form-item label="费率">
                            <a-input v-decorator="['feilv', { rules: [{ required: true, message: '请填写费率' }] }]"
                                :disabled="isEdit ? false : true"  addon-after="‰"></a-input>
                    </a-form-item>

                    <!-- <a-form-item label="名称截取间断" style="margin-bottom:0;">
                        <a-form-item  :style="{ display: 'inline-block', width: 'calc(50% - 12px)' }">

                            <a-input  placeholder="请输入名称开始截取字符" v-decorator="['tou_name_start', { rules: [{ required: true, message: '请输入名称开始截取字符' }, { max: 20, message: '最大长度不能超过20' }] }]"
                                :disabled="isEdit ? false : true"></a-input>
                        </a-form-item>

                        <span :style="{ display: 'inline-block', width: '24px', textAlign: 'center' }">
                            -
                        </span>

                        <a-form-item  :style="{ display: 'inline-block', width: 'calc(50% - 12px)' }">
                            <a-input  placeholder="请输入结束开始截取字符" v-decorator="['tou_name_end', { rules: [{ required: true, message: '请输入结束开始截取字符' }, { max: 20, message: '最大长度不能超过20' }] }]"
                                :disabled="isEdit ? false : true"></a-input>
                        </a-form-item>
                    </a-form-item>

                    
                    <a-form-item label="社会信用代码截取间断" style="margin-bottom:0;">
                        <a-form-item  :style="{ display: 'inline-block', width: 'calc(50% - 12px)' }">

                            <a-input  placeholder="请输入开始截取字符" v-decorator="['tou_sn_start', { rules: [{ required: true, message: '请输入开始截取字符' }, { max: 20, message: '最大长度不能超过20' }] }]"
                                :disabled="isEdit ? false : true"></a-input>
                        </a-form-item>

                        <span :style="{ display: 'inline-block', width: '24px', textAlign: 'center' }">
                            -
                        </span>

                        <a-form-item  :style="{ display: 'inline-block', width: 'calc(50% - 12px)' }">
                            <a-input  placeholder="请输入结束截取字符" v-decorator="['tou_sn_end', { rules: [{ required: true, message: '请输入结束截取字符' }, { max: 20, message: '最大长度不能超过20' }] }]"
                                :disabled="isEdit ? false : true"></a-input>
                        </a-form-item>
                    </a-form-item>
                    
                    <a-form-item label="车牌截取间断" style="margin-bottom:0;">
                        <a-form-item  :style="{ display: 'inline-block', width: 'calc(50% - 12px)' }">

                            <a-input  placeholder="请输入开始截取字符" v-decorator="['tou_chepai_start', { rules: [{ required: true, message: '请输入开始截取字符' }, { max: 20, message: '最大长度不能超过20' }] }]"
                                :disabled="isEdit ? false : true"></a-input>
                        </a-form-item>

                        <span :style="{ display: 'inline-block', width: '24px', textAlign: 'center' }">
                            -
                        </span>

                        <a-form-item  :style="{ display: 'inline-block', width: 'calc(50% - 12px)' }">
                            <a-input  placeholder="请输入结束截取字符" v-decorator="['tou_chepai_end', { rules: [{ required: true, message: '请输入结束截取字符' }, { max: 20, message: '最大长度不能超过20' }] }]"
                                :disabled="isEdit ? false : true"></a-input>
                        </a-form-item>
                    </a-form-item>
                    
                    <a-form-item label="车架截取间断" style="margin-bottom:0;">
                        <a-form-item  :style="{ display: 'inline-block', width: 'calc(50% - 12px)' }">

                            <a-input  placeholder="请输入开始截取字符" v-decorator="['tou_chejia_start', { rules: [{ required: true, message: '请输入开始截取字符' }, { max: 20, message: '最大长度不能超过20' }] }]"
                                :disabled="isEdit ? false : true"></a-input>
                        </a-form-item>

                        <span :style="{ display: 'inline-block', width: '24px', textAlign: 'center' }">
                            -
                        </span>

                        <a-form-item  :style="{ display: 'inline-block', width: 'calc(50% - 12px)' }">
                            <a-input  placeholder="请输入结束截取字符" v-decorator="['tou_chejia_end', { rules: [{ required: true, message: '请输入结束截取字符' }, { max: 20, message: '最大长度不能超过20' }] }]"
                                :disabled="isEdit ? false : true"></a-input>
                        </a-form-item>
                    </a-form-item> -->
                    


                </a-col>
            </a-row>

        </a-form>
        <!-- 提交按钮 -->
        <div class="buttons">
            <a-button type="primary" v-if="!isEdit" @click="isEdit = true">编辑</a-button>
            <a-button type="primary" v-else @click="save">保存</a-button>
        </div>
    </div>
</template>

<script>
let id = 0;
export default {
    name: 'settingPage',
    data() {
        return {
            str: '',
            feilv: 1.8,
            isEdit: false
        };
    },
    async beforeCreate() {
        this.form = this.$form.createForm(this, {
            name: 'order',
            mapPropsToFields: () => {
                return {
                    fangan: this.$form.createFormField({
                        value: ''
                    }),
                    feilv: this.$form.createFormField({
                        value: ''
                    })
                    // tou_name_start: this.$form.createFormField({
                    //     value: ''
                    // }),
                    // tou_name_end: this.$form.createFormField({
                    //     value: ''
                    // }),
                    // tou_sn_start: this.$form.createFormField({
                    //     value: ''
                    // }),
                    // tou_sn_end: this.$form.createFormField({
                    //     value: ''
                    // }),
                    // tou_chepai_start: this.$form.createFormField({
                    //     value: ''
                    // }),
                    // tou_chepai_end: this.$form.createFormField({
                    //     value: ''
                    // }),
                    // tou_chejia_start: this.$form.createFormField({
                    //     value: ''
                    // }),
                    // tou_chejia_end: this.$form.createFormField({
                    //     value: ''
                    // })

                };
            },
        });
    },
   async created() {
        await this.getSetting()
    },
    methods: {
        async getSetting () {
            const r = await this.$axios.get("/settings/getSettings");
            this.form.setFieldsValue({
                fangan: r.data.fangan,
                feilv: r.data.feilv,
                // tou_name_start: '',
                // tou_name_end: ''
          });
        },
        save() {
            const that = this
            this.form.validateFields(async (err, value) => {
                if (!err) {
                    const form = JSON.parse(JSON.stringify(value))
                    const r = await this.$axios.post("/settings/update", form);
                    if (r.status == 1) {
                        // this.$message.success('操作成功')
                        that.isEdit = false
                        setTimeout(() => {
                            that.getSetting()
                        }, 2000);
                    }
                }
            });
        },
    },
};
</script>
<style>
.dynamic-delete-button {
    cursor: pointer;
    position: relative;
    top: 4px;
    font-size: 24px;
    color: #999;
    transition: all 0.3s;
}

.dynamic-delete-button:hover {
    color: #777;
}

.dynamic-delete-button[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
}
</style>