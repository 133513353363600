<template>
  <a-modal title="添加管理员" :visible="true" @cancel="cancel" @ok="sub">
    <a-form
      :label-col="formItemLayout.labelCol"
      :wrapper-col="formItemLayout.wrapperCol"
    >
      <a-form-item label="权限">
        <a-radio-group style="width: 350px" v-model="form.group_id">
          <a-radio
            v-for="(group, index) in groupList"
            :key="index"
            :value="group.id"
            >{{ group.group_name }}</a-radio
          >
        </a-radio-group>
        <i class="must">*</i>
      </a-form-item>
      <a-form-item label="姓名">
        <a-input
          style="width: 350px"
          v-model="form.name"
          placeholder="请输入姓名"
        ></a-input>
        <i class="must">*</i>
      </a-form-item>
      <a-form-item label="用户名">
        <a-input
          style="width: 350px"
          v-model="form.username"
          placeholder="请输入用户名"
        ></a-input>
        <i class="must">*</i>
      </a-form-item>
      <a-form-item label="手机">
        <a-input
          style="width: 350px"
          v-model="form.tel"
          placeholder="请输入手机号"
        ></a-input>
        <i class="must">*</i>
      </a-form-item>

      <a-form-item label="备注">
        <a-textarea
          style="width: 350px"
          v-model="form.desc"
          placeholder="请输入备注"
          :auto-size="{ minRows: 3, maxRows: 6 }"
        />
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
export default {
  props: ['d'],
  data() {
    return {
      formItemLayout: {
        labelCol: {
          span: 4,
        },
        wrapperCol: {
          span: 20,
        },
      },
      groupList: [],
      form: {
        id: null,
      },
    };
  },
  created() {
    this.form = { ...this.d };
    this.getGroupList();
  },
  methods: {
    cancel() {
      this.$emit("callback", 0);
    },
    async getGroupList() {
      const r = await this.$axios.get("/user/getGroupList");
      // console.log(r);
      if (r.status == 1) this.groupList = r.list;
    },
    async sub() {
      // console.log(this.form);
      if (!this.form.group_id) return this.$message.error("请选择权限");
      if (!this.form.name) return this.$message.error("请输入姓名");
      if (!this.form.tel) return this.$message.error("请输入手机号");
      if (!this.form.username) return this.$message.error("请输入用户名");

      // 提交表单
      const r = await this.$axios.post("/user/update", this.form);

      if (r.status == 1) {
        this.$emit("callback", 1);
      }
    },
  },
};
</script>