<template>
    <a-upload-dragger :key="nowKey" name="file" :multiple="mult" :action="route" :headers="headers"
        @change="handleChange" :show-upload-list="false" :before-upload="beforeUpload" :data="data">
        <p class="ant-upload-drag-icon">
            <a-icon type="inbox" />
        </p>
        <p class="ant-upload-text">
            点击或者拖拽文件到此区域上传
        </p>
        <!-- <p class="ant-upload-hint">
        Support for a single or bulk upload. Strictly prohibit from uploading company data or other
        band files
        </p> -->
    </a-upload-dragger>
</template>

<script>
// import * as imageConversion from 'image-conversion'
export default {
    props: ["mult", "folder", "oldUrl", "allow", "type"],
    data() {
        return {
            route: this.API + "/file/uploadFileNoOss",
            headers: {
                token: "",
            },
            data: {},
            fileList: [],
            imageUrl: '',
            loading: false,
            tupianleixing: '',
            shibieData: [],
            nums: 0,
            nowKey: Date.now(),
            shibieResult: {
                successList: [],
                failList: []
            }
        };
    },
    watch: {
        // oldFiles() {
        //     if (this.oldFiles.length > 0) {
        //         this.renderFiles();
        //     }
        // },
        oldUrl() {
            this.imageUrl = this.API + this.oldUrl
        }
    },
    created() {
        if (this.oldUrl) {
            this.imageUrl = this.API + this.oldUrl
        }
        this.tupianleixing = 'pdf'
        this.headers.token = this.$store.state.token;
        this.data.folder = this.folder || "other";
    },
    methods: {
        async handleChange(info) {
            const that = this

            if (["done", "removed", 'error'].includes(info.file.status)) {
                that.nums++ //计算是否上传完成
                if (info.file.status == 'done' && info.file.response.status == 1) {
                    that.fileList.push(info.file.response)
                }
                if (that.nums == info.fileList.length) {
                    that.$message.loading('正在识别', 0)
                    info.fileList = []
                    that.nowKey = Date.now()
                    that.nums = 0
                    that.doShibie()
                }
            }
        },
        beforeUpload(file) {
            this.$message.destroy()
            this.$message.loading('正在上传', 0)

            if (file.type.indexOf('pdf') == -1) {
                this.$message.destroy()
                this.$message.error('只能上传pdf格式图片!');
                return false
            }
            const isLt10M = file.size / 1024 / 1024 < 15;
            if (!isLt10M) {
                this.$message.destroy()
                this.$message.error('图片大小不能超过15MB!');
                return false
            }
            return true
        },
        async doShibie() {
            const that = this
            if (this.fileList.length == 0) {
                return
            }
            const filePath = []
            for (let key in this.fileList) {
                filePath.push({
                    name: this.fileList[key].fileObj.name,
                    full_path: this.fileList[key].full_path,
                    file_path: this.fileList[key].file_path
                })
            }
            const apiUrl = this.folder == 'fapiao' ? '/file/parseFapiaoPdf' : '/file/parsePdf'
            const r = await that.$axios.post(apiUrl, {
                filePath: filePath
            })
            if (r.status == 1) {

                for (let item of r.data) {
                    //证明识别成功
                    if (item.isShibie) {
                        that.shibieResult.successList.push(
                            item
                        )
                    } else {
                        that.shibieResult.failList.push(
                            item
                        )
                    }
                }

                that.fileList = []
                that.$message.destroy()
                that.$emit('callbackPdf', that.shibieResult)
            }
        }
    },
};
</script>

<style lang="scss" scoped>
.ant-upload-text {
    font-size: 14px;
}
</style>