<template>
  <div class="component-wrap">
    <a-form class="form sec" :label-col="{ span: 10 }" :wrapper-col="{ span: 10 }">

      <a-form-item label="权限">
        <div v-if="form.group_id == 2">代理</div>
        <div v-if="form.group_id == 3">内勤</div>
        <div v-if="form.group_id == 4">二级代理</div>
      </a-form-item>

      <a-form-item v-if="form.group_id == 4" label="上级">
        {{ form.shangji_name }}
      </a-form-item>

      <a-form-item label="代理姓名">
        {{ form.name }}
      </a-form-item>

      <a-form-item label="手机号">
        {{ form.tel }}
      </a-form-item>
      <!-- 
      <a-form-item label="登录密码">
        <a-input
          type="password"
          style="width: 60%"
          placeholder="请输入登录密码"
          v-decorator="['password', { rules: [{ required: true, message: '请输入登录密码' }] }]"
        ></a-input>
      </a-form-item>

      <a-form-item label="确认密码">
        <a-input
          type="password"
          style="width: 60%"
          placeholder="请确认密码"
          v-decorator="['confirmPassword', { rules: [
            { required: true, message: '' },
            { validator: validatorPassword }
            ] }]"
        ></a-input>
      </a-form-item> -->

      <a-form-item label="身份证号">
        {{ form.IDcard }}
      </a-form-item>

      <a-form-item label="家庭地址">
        {{ form.addr }}
      </a-form-item>

      <a-form-item label="备注信息">
        {{ form.desc }}
      </a-form-item>

      <a-form-item label="修改密码">
        <a-button type="primary" @click="showXiugaimima" style="margin-right: 10px;">修改密码</a-button>
        
        <a-popconfirm title="确定要重置密码吗" @confirm="resetPassword" okText="确定" cancelText="取消">
          <a-button type="primary">重置密码</a-button>
        </a-popconfirm>
      </a-form-item>
    </a-form>
    <div class="buttons">
      <a-button type="primary" @click="sub">编辑</a-button>
    </div>
    <UpdatePassword v-if="showUpdatePassword" :id="this.id" @upDataPSDResult="upDataPSDResult"/>
  </div>
</template>

<style lang='scss' scoped>
hr {
  border-top: none;
  margin: 40px;
}

h4 {
  margin-left: 20vw;
}
</style>

<script>
import UpdatePassword from './components/editPassword.vue'

export default {
  name: 'dailiDetail',
  components: { UpdatePassword },
  data() {
    return {
      id: null,
      form: {},
      showUpdatePassword: false
    };
  },
  async created() {
    this.id = this.$route.query.id;
    const r = await this.$axios.get("/user/getInfo?id=" + this.id);
    this.form = r.info
  },
  mounted() { },
  methods: {
    showXiugaimima () {
      this.showUpdatePassword = true
    },
    async sub() {
      this.$router.push("/daili/update?id=" + this.id);
    },
    upDataPSDResult(e) {
      if(e == 1) this.showUpdatePassword = false
    },
    async resetPassword() {
      await this.$axios.post("/user/resetPassword", {id: this.form.id, username: this.form.username})
    }
  },
};
</script>