<template>
    <div class="page">
        <!-- 检索条件 类型 客户 电话 车牌号 创建时间段 服务时间段 状态 -->
        <a-form class="ctrl" layout="inline">

            <a-form-item label="投保人">
                <a-input type="text" placeholder="请输入投保人" v-model="options.tou_name"></a-input>
            </a-form-item>

            <a-form-item label="车牌号">
                <a-input type="text" placeholder="请输入车牌号" v-model="options.chepai"></a-input>
            </a-form-item>

            <a-form-item label="保险期间">
                <a-date-picker @change="getList" v-model="options.start_at" /> -
                <a-date-picker @change="getList" v-model="options.end_at" />
            </a-form-item>

            <a-form-item label="投保时间">
                <a-date-picker @change="getList" v-model="options.create_start_at" /> -
                <a-date-picker @change="getList" v-model="options.create_end_at" />
            </a-form-item>

            <a-form-item label="状态">
                <a-select show-search placeholder="请选择状态" option-filter-prop="children" v-model="options.status"
                    style="width: 100px;" @change="getList">
                    <a-select-option :value="-10">
                        全部
                    </a-select-option>
                    <a-select-option :value="1">
                        未锁定
                    </a-select-option>
                    <a-select-option :value="2">
                        已锁定
                    </a-select-option>
                    <a-select-option :value="3">
                        已下载
                    </a-select-option>
                </a-select>
            </a-form-item>

            <a-form-item label="标记">
                <a-input type="text" placeholder="请输入标记" v-model="options.biaoji"></a-input>
            </a-form-item>

            <a-form-item label="保单号">
                <a-input type="text" placeholder="请输入保单号" v-model="options.baoxiandanhao"></a-input>
            </a-form-item>

            <a-form-item>
                <a-button type="primary" @click="getList">搜索</a-button>
                <a-button type="primary" style="margin-left: 5px;" @click="daochu">导出</a-button>
                <!-- <a-button style="margin-left: 5px;" type="primary" @click="duogeDabao">多个打包</a-button> -->
                <!-- <a-popconfirm title="确定要锁定吗？" ok-text="确认" cancel-text="取消" @confirm="updateDuogeStatus">
                    <a-button style="margin-left: 5px;" type="danger">多个锁定</a-button>
                </a-popconfirm> -->
            </a-form-item>
        </a-form>

        <div class="statistics">
            <span>共{{ pages.amount }}条，保费共 {{ fee }} 元</span>
            <span v-if="selectedRowKeys.length">
                <a-button style="margin-left: 5px;" type="primary" @click="duogeBaodan">下载投保单</a-button>
                <a-button style="margin-left: 5px;" type="primary" @click="duogePdfBaodan">下载保单</a-button>
                <!-- <a-button v-if="[1, 3, '1', '3'].includes(userData.group_id)" style="margin-left: 5px;" type="primary" @click="duogeDabao">打包下载</a-button> -->
            </span>
        </div>

        <a-table class="sec" size="small" :rowKey="(record) => record.id" bordered :columns="columns"
            :data-source="list" :pagination="false"
            :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }">

            <!-- <template slot="xuhao" slot-scope="text, record">
                {{ list.indexOf(record) + 1 }}
            </template> -->

            <template slot="uploadfiles" slot-scope="text, record">
                <span v-if="record.gaozhishuUrl && record.gaozhishuUrl.length">开票信息{{ record.gaozhishuUrl.length
                    }}</span>
                <span style="margin-left: 10px;" v-if="record.tiaokuanUrl && record.tiaokuanUrl.length">条款{{
                    record.tiaokuanUrl.length }}</span>
                <span style="margin-left: 10px;" v-if="record.toubaodanUrl && record.toubaodanUrl.length">投保单{{
                    record.toubaodanUrl.length }}</span>
            </template>

            <template slot="baodan" slot-scope="text, record">
                <a-tooltip v-if="record.pdfUrl">
                    <template slot="title">
                        查看
                    </template>
                    <a-button @click="xiazaiHetong(record.pdfUrl)" style="margin-left: 5px" size="small"
                        icon="eye"></a-button>
                </a-tooltip>
            </template>
            <template slot="zhuangtai" slot-scope="text, record">
                <div v-if="record.status == 1">未锁定</div>
                <div v-if="record.status == 2">已锁定</div>
                <div v-if="record.status == 3">已下载</div>
            </template>

            <template slot="action" slot-scope="text, record">
                <a-tooltip>
                    <template slot="title">
                        查看
                    </template>
                    <a-button @click="jumpUrl('/order/info?id=' + record.id)" style="margin-left: 5px" size="small"
                        icon="eye"></a-button>
                </a-tooltip>

                <a-tooltip v-if="record.status == 1">
                    <template slot="title">
                        上传
                    </template>
                    <a-button @click="showShangchuan(record)" style="margin-left: 10px" size="small"
                        icon="cloud-upload"></a-button>
                </a-tooltip>

                <!-- <a-tooltip v-if="[1, 3, '1', '3'].includes(userData.group_id)">
                    <template slot="title">
                        {{ record.status < 2 ? '管理员锁定后可下载' : '打包下载' }} </template>
                            <a-button @click="dabao(record.id)" :disabled="record.status < 2" style="margin-left: 10px"
                                size="small" type="primary" icon="cloud-download"></a-button>
                </a-tooltip> -->
                <!-- <a-button style="margin-left: 5px" size="small" @click="xiazaiHetong(record.baodan_url)" type="">
                    合同下载
                </a-button>

                <a-button style="margin-left: 5px" size="small" @click="yulanHetong(record.baodan_url)" type="">
                    合同预览
                </a-button> -->

                <!-- <a-button v-if="record.status == 1" @click="jumpUrl('/order/edit?id=' + record.id)"
                    style="margin-left: 5px" size="small" type="primary">
                    编辑
                </a-button> -->

                <!-- <a-popconfirm v-if="record.status != 2" title="确定要锁定吗？" ok-text="确认" cancel-text="取消"
                    @confirm="updateStatus({ id: record.id, status: 2 })">
                    <a-button type="danger" size="small" style="margin-left: 5px">锁定</a-button>
                </a-popconfirm>
                <a-popconfirm v-else title="确定要取消锁定吗？" ok-text="确认" cancel-text="取消"
                    @confirm="updateStatus({ id: record.id, status: 1 })">
                    <a-button type="danger" size="small" style="margin-left: 5px">取消锁定</a-button>
                </a-popconfirm> -->

                <!-- <a-button @click="dabao(record.id)" style="margin-left: 5px" size="small" type="primary">
                    打包
                </a-button> -->
                <!-- <a-popconfirm  title="确定要删除吗？" ok-text="确认" cancel-text="取消"
                    @confirm="del(record.id)">
                    <a-button type="danger" size="small" style="margin-left: 5px">删除</a-button>
                </a-popconfirm> -->

                <a-dropdown>
                    <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                        更多 <a-icon type="down" />
                    </a>
                    <a-menu slot="overlay">
                        <a-menu-item key="1" @click="xiazaiHetong(record.baodan_url)">
                            合同下载
                        </a-menu-item>
                        <a-menu-item key="2" @click="yulanHetong(record.baodan_url)">
                            合同预览
                        </a-menu-item>
                        <a-menu-item v-if="record.status == 1" key="4" @click="jumpUrl('/order/edit?id=' + record.id)">
                            编辑
                        </a-menu-item>
                        <a-menu-item key="3">
                            <a-popconfirm v-if="record.status == 1" title="确定要删除吗？" ok-text="确认" cancel-text="取消"
                                @confirm="del(record.id)">
                                <div style="width: 100%;">
                                    删除
                                </div>
                            </a-popconfirm>
                        </a-menu-item>
                    </a-menu>
                </a-dropdown>

            </template>
        </a-table>

        <a-pagination class="pagination" showQuickJumper v-model="pages.now_page" :defaultPageSize="50"
            :total="pages.amount" @change="getList" />

        <WordYulan v-if="yulanData.show" :wordUrl="yulanData.url" @callback="closeYulan" />
        <UploadPicMultModel v-if="shangchuan.show" @updateCallBack="updateCallBack"
            :oldData="{ gaozhishuUrl: uploadRecord.gaozhishuUrl, tiaokuanUrl: uploadRecord.tiaokuanUrl, toubaodanUrl: uploadRecord.toubaodanUrl }" />
    </div>
</template>

<style lang='scss' scoped></style>

<script>
import WordYulan from '../../components/wordYulan.vue';
import UploadPicMultModel from '../../components/uploadPicMultModel.vue';

const columns = [
    {
        title: "#",
        dataIndex: "id",
        // scopedSlots: {
        //   customRender: "xuhao",
        // }
    },
    //   {
    //     title: "#",
    //     dataIndex: "id",
    //     // scopedSlots: {
    //     //   customRender: "xuhao",
    //     // }
    //   },
    {
        title: "承保单位",
        dataIndex: "chengbaodanwei",
    },
    {
        title: "投保人",
        dataIndex: "tou_name",
    },
    {
        title: "车牌",
        dataIndex: "chepai",
    },
    {
        title: "人数",
        dataIndex: "guyuanrenshu",
    },
    {
        title: "方案（万）",
        dataIndex: "peichangfangan",
    },
    {
        title: "保费",
        dataIndex: "baoxianfei",
    },

    {
        title: "投保时间",
        dataIndex: "create_time",
    },

    {
        title: "上传照片",
        scopedSlots: {
            customRender: "uploadfiles",
        }
    },
    {
        title: "保单",
        scopedSlots: {
            customRender: "baodan",
        }
    },
    {
        title: "标记备注",
        dataIndex: "biaojiRemark",
    },
    {
        title: "状态",
        scopedSlots: {
            customRender: "status",
        }
    },
    {
        title: "操作",
        scopedSlots: {
            customRender: "action",
        }
    },
];


export default {
    name: 'myOrderList',
    components: { WordYulan, UploadPicMultModel },
    data() {
        return {
            userData: JSON.parse(localStorage.getItem('user')),
            options: {
                tou_name: '',
                start_at: null,
                end_at: null,
                chepai: '',
                create_time: null,
                create_start_at: null,
                create_end_at: null,
                biaoji: '',
                baoxiandanhao: '',
                status: -10
            },
            columns,
            list: [],
            fee: 0,
            dailiList: [],
            pages: {
                now_page: 1,
                amount: 0,
                total_fee: 0,
            },
            name: '',
            id: '',
            selectedRowKeys: [],
            yulanData: {
                show: false,
                url: ''
            },
            shangchuan: {
                show: false
            },
            uploadRecord: {}
        };
    },
    computed: {
        rowSelection() {
            return {
                onChange: (selectedRowKeys, selectedRows) => {
                    console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
                },
                getCheckboxProps: record => ({
                    props: {
                        disabled: record.name === 'Disabled User', // Column configuration not to be checked
                        name: record.name,
                    },
                }),
            };
        },
    },
    activated() {
        this.init()
    },
    // async created() {

    // },
    methods: {
        async init() {
            if (this.$route.query.chepai) {
                this.options.chepai = this.$route.query.chepai
            }

            if (this.$route.query.tou_name) {
                this.options.tou_name = this.$route.query.tou_name
            }
            await this.getList();
        },
        filterOption(input, option) {
            return (
                option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
            );
        },
        goto(path, item) {
            this.$router.push(path);
        },
        async getList() {
            this.selectedRowKeys = []
            const options = { ...this.options };
            options.nowPage = this.pages.now_page;
            const r = await this.$axios.post("/order/getMyList", options);
            if (r.status == 1) {
                this.list = r.list;
                this.pages.amount = r.amount;
                this.fee = r.fee;
            }
        },
        async del(id) {
            const r = await this.$axios.post("/order/delOrder", {
                id: id,
            });
            if (r.status == 1) {
                this.getList();
            }
        },
        xiazaiHetong(url) {
            if(!url.includes('project/')) {
                window.open(this.ossUrl + '/project' + url)
            }else{
                window.open(this.ossUrl + url)
            }
        },

        yulanHetong(url) {
            this.yulanData = {
                show: true,
                url: url.includes('project/') ? this.ossUrl + url : this.ossUrl + '/project' + url
            }
        },
        closeYulan() {
            this.yulanData = {
                show: false,
                url: ''
            }
        },
        jumpUrl(url) {
            this.$router.push(url)
            // let routeData = this.$router.resolve({ path: url });
            // window.open(routeData.href, '_blank');
        },
        async dabao(id) {
            const r = await this.$axios.post("/file/downloadZip", {
                order_id: id,
            });
            if (r.status == 1) {
                window.open(r.url)
                this.getList()
            }
        },
        onSelectChange(selectedRowKeys) {
            this.selectedRowKeys = selectedRowKeys;
        },
        async duogeDabao() {
            if (this.selectedRowKeys.length == 0) {
                this.$message.error('没有选中的订单')
                return
            }
            const r = await this.$axios.post("/file/downloadZips", {
                order_id: this.selectedRowKeys
            });
            if (r.status == 1) {
                window.open(r.url)
            }
        },
        async updateDuogeStatus() {
            if (this.selectedRowKeys.length == 0) {
                this.$message.error('没有选中的订单')
                return
            }
            const that = this
            const r = await this.$axios.post("/order/updateDuogeStatus", {
                order_ids: that.selectedRowKeys,
                status: 2
            });
            if (r.status == 1) {
                this.getList()
            }
        },
        async updateStatus(record) {
            const r = await this.$axios.post("/order/updateStatus", {
                order_id: record.id,
                status: record.status
            });
            if (r.status == 1) {
                this.getList()
            }
        },
        async showShangchuan(record) {
            this.uploadRecord = JSON.parse(JSON.stringify(record))
            this.uploadRecord.gaozhishuUrl = record.gaozhishuUrl
            this.uploadRecord.tiaokuanUrl = record.tiaokuanUrl
            this.uploadRecord.toubaodanUrl = record.toubaodanUrl
            this.shangchuan.show = true
            // this.$message.error('尚未完成')
        },
        async updateCallBack(e) {
            const that = this
            this.shangchuan.show = false
            if (e) {
                // 直接把e传到后台保存到数据库
                const r = await this.$axios.post("/order/updateTupian", {
                    id: that.uploadRecord.id,
                    data: e
                })
                this.getList()
            }
            this.uploadRecord = {}
        },

        async duogeDabao() {
            this.$message.loading('正在打包', 0)
            if (this.selectedRowKeys.length == 0) {
                setTimeout(() => {
                    this.$message.destroy()
                }, 2000)
                this.$message.error('没有选中的订单')
                return
            }

            // 检测是否已经都锁定
            for (let item of this.list) {
                if (this.selectedRowKeys.includes(item.id)) {
                    if (item.status < 2) {
                        this.$message.error('请先锁定')
                        setTimeout(() => {
                            this.$message.destroy()
                        }, 2000)
                        return 
                    }
                }
            }

            const r = await this.$axios.post("/file/downloadZips", {
                order_id: this.selectedRowKeys
            });
            if (r.status == 1) {
                window.open(r.url)
                this.getList()
            }
            setTimeout(() => {
                this.$message.destroy()
            }, 2000)
            // console.log(r)
        },
        async duogeBaodan() {
            this.$message.loading('正在打包', 0)
            if (this.selectedRowKeys.length == 0) {
                setTimeout(() => {
                    this.$message.destroy()
                }, 2000)
                this.$message.error('没有选中的订单')
                return
            }
            const r = await this.$axios.post("/file/downloadBaodanZips", {
                order_id: this.selectedRowKeys
            });
            if (r.status == 1) {
                window.open(r.url)
            }
            setTimeout(() => {
                this.$message.destroy()
            }, 2000)
        },
        async duogePdfBaodan() {
            this.$message.loading('正在打包', 0)
            if (this.selectedRowKeys.length == 0) {
                this.$message.error('没有选中的订单')
                setTimeout(() => {
                    this.$message.destroy()
                }, 2000)
                return
            }
            const r = await this.$axios.post("/file/downloadPdfBaodanZips", {
                order_id: this.selectedRowKeys
            });
            if (r.status == 1) {
                window.open(r.url)
            }
            setTimeout(() => {
                this.$message.destroy()
            }, 2000)
        },
        async daochu() {
            this.$message.loading('正在导出', 0)
            const r = await this.$axios.post("/order/myDaochu", this.options)
            location.href = this.API + r
            setTimeout(() => {
                this.$message.destroy()
            }, 2000)
        }
    },
};
</script>