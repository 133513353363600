<template>
  <div>
    <!-- <a-button @click="test">test</a-button> -->
  </div>
</template>

<style lang='scss' scoped>
</style>

<script>
export default {
  data() {
    return {
      
    }
  },
  beforeCreate () {},
  created () {
    // if(this.$store.state.user.group_id == 2) {
      this.$router.push('/order/myList')
    // } else {
    //   this.$router.push('/order/list')
    // }
  },
  mounted () {},
  methods: {
    test () {
      this.$axios.get('/test')
    }
  }
}
</script>