<template>
  <a-tabs style="margin: 5px 5px 0 5px;" size="small" v-model:activeKey="activeKey" type="editable-card" @change="navTo"
    @edit="close" :hideAdd="true">
    <template #addIcon>
      <a-tooltip>
        <template #title>关闭所有窗口</template>
        <close-outlined />
      </a-tooltip>
    </template>
    <a-tab-pane v-for="(route, index) in routes" :key="index" :tab="route.title"
      :closable="route.closable"></a-tab-pane>
  </a-tabs>
</template>

<style lang="scss">
.ant-tabs-top>.ant-tabs-nav,
.ant-tabs-bottom>.ant-tabs-nav,
.ant-tabs-top>div>.ant-tabs-nav,
.ant-tabs-bottom>div>.ant-tabs-nav {
  margin: 0 !important;
  user-select: none;
}
</style>

<script>
export default({
  data() {
    return {
      activeKey: 0,
      routes: []
    };
  },
  watch: {
    "$route.fullPath"() {
      // 检测是否已存在 
      let index = -1;
      for (let i = 0; i < this.routes.length; i++) {
        if (this.routes[i].fullPath === this.$route.fullPath) {
          index = i;
          break
        }
      }
      // 如果已存在直接高亮
      if (index > -1) {
        this.activeKey = index;
      }
      // 如果不存在新增，并且高亮
      else {
        const r = {
          title: this.$route.query.title || this.$route.meta.title,
          name: this.$route.name,
          fullPath: this.$route.fullPath,
        }
        this.routes.push(r);
        this.activeKey = this.routes.length - 1;
      }

      this.$store.commit('saveKeepRoutes', this.routes)
    }
  },
  mounted() {
    const that = this;
    // console.log('this.$store.state.user.group_id-------------' )
    // console.log(this.$store.state.user.group_id )
    // if (this.$store.state.user.group_id == 2) {
    //   this.routes = [
    //     {
    //       title: '我的订单',
    //       name: 'myOrderList',
    //       fullPath: '/order/myList',
    //     }
    //   ]
    // } else {
    //   this.routes = [
    //     {
    //       title: '订单列表',
    //       name: 'orderList',
    //       fullPath: '/order/list',
    //     }
    //   ]
    // }
    // 监听快捷键关闭tab
    document.onkeydown = function (e) {
      if (e.key.toLowerCase() == 'w' && (e.ctrlKey || e.metaKey)) {
        e.preventDefault();
        that.close(that.activeKey, 'remove')
        return false
      }
    }
  },
  methods: {
    navTo() {
      const route = this.routes[this.activeKey];
      this.$router.push(route.fullPath)
    },
    close(index, action) {
      if (action == 'remove') {
        // 如果是最后一个，跳到首页去
        if (this.routes.length === 1) {
          this.closeAll()
          return
        }
        else {
          this.routes.splice(index, 1);
          // 如果关闭了当前页面，跳转到最后一个去
          if (index == this.activeKey) {
            this.$router.push(this.routes[this.routes.length - 1].fullPath)
            this.activeKey--
          }
          else if (index < this.activeKey) {
            this.activeKey--
          }
        }

        this.$store.commit('saveKeepRoutes', this.routes)
      }
      // 关闭所有
      else {
        this.closeAll()
      }
    },
    closeAll() {
      // if (this.$store.state.user.group_id == 2) {
        this.routes = [{
          title: '我的订单',
          name: 'myOrderList',
          fullPath: '/order/myList',
        }]
        this.activeKey = 0;
        this.$router.push('/order/myList')
      // } else {
      //   this.routes = [{
      //     title: '订单列表',
      //     name: 'orderList',
      //     fullPath: '/order/list',
      //   }]
      //   this.activeKey = 0;
      //   this.$router.push('/order/list')
      // }


      this.$store.commit('saveKeepRoutes', this.routes)
    }
  }
});
</script>