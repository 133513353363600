<template>
  <div class="page">
    <a-form
      class="form sec"
      :label-col="{ span: 5 }"
      :wrapper-col="{ span: 8 }"
    >
      <a-form-item label="权限">{{form.group_name}}</a-form-item>
      <a-form-item label="姓名">{{form.name}}</a-form-item>
      <a-form-item label="电话">{{form.tel}}</a-form-item>
      <a-form-item label="用户名">{{form.username}}</a-form-item>
    </a-form>
  </div>
</template>

<style lang='scss' scoped>
.flex-item {
  display: flex;
}
.sec {
  margin: 15px;
  padding: 40px;
}
</style>

<script>
export default {
  data() {
    return {
      form: {},
    };
  },
  async created() {
    const r = await this.$axios.get("/user/getUserInfo");
    this.form = r.info
  },
  methods: {
    
  },
};
</script>