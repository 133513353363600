<template>
  <div class="component-wrap">
    <a-form :form="form" class="form sec" :label-col="{ span: 10 }" :wrapper-col="{ span: 10 }">
      <a-form-item v-if="[1, '1', 3, '3'].includes($store.state.user.group_id)" label="权限">
        <a-radio-group v-decorator="['group_id', { rules: [{ required: true }] }]" >
          <a-radio :value="2">
            代理
          </a-radio>
          <a-radio :value="3">
            内勤
          </a-radio>
        </a-radio-group>
      </a-form-item>

      <a-form-item label="姓名">
        <a-input style="width: 60%" placeholder="请输入姓名"
          v-decorator="['name', { rules: [{ required: true, message: '请输入姓名' }] }]">
        </a-input>
      </a-form-item>

      <a-form-item label="手机号">
        <a-input style="width: 60%" placeholder="请输入手机号" v-decorator="['tel', {
      rules: [
        {
          required: true,
          message: ''
        },
        {
          validator: validateTel,
        }
      ]
    }]"></a-input>
      </a-form-item>

      <a-form-item label="登录密码">
        <a-input type="password" style="width: 60%" placeholder="请输入登录密码"
          v-decorator="['password', { rules: [{ required: true, message: '请输入登录密码' }] }]"></a-input>
      </a-form-item>

      <a-form-item label="确认密码">
        <a-input type="password" style="width: 60%" placeholder="请确认密码" v-decorator="['confirmPassword', {
      rules: [
        { required: true, message: '' },
        { validator: validatorPassword }
      ]
    }]"></a-input>
      </a-form-item>

      <a-form-item label="身份证号">
        <a-input style="width: 60%" placeholder="请输入身份证号" v-decorator="['IDcard', {
      rules: [
        {
          required: true,
          message: ''
        },
        {
          validator: validateIDcard,
        }]
    }]"></a-input>
      </a-form-item>

      <a-form-item label="家庭地址">
        <a-textarea style="width: 60%" placeholder="请输入家庭地址" v-decorator="['addr']" />
      </a-form-item>


      <a-form-item label="备注信息">
        <a-textarea style="width: 60%" placeholder="请输入备注信息" v-decorator="['desc']"
          :auto-size="{ minRows: 3, maxRows: 6 }" />
      </a-form-item>
    </a-form>
    <div class="buttons">
      <a-button type="primary" @click="sub">提交</a-button>
    </div>
  </div>
</template>

<style lang='scss' scoped>
hr {
  border-top: none;
  margin: 40px;
}

h4 {
  margin-left: 20vw;
}
</style>

<script>
export default {
  name: 'dailiCreate',
  data() {
    return {
      form: null,
    };
  },
  async created() {
    this.form = this.$form.createForm(this, {
      name: 'dailiForm',
      mapPropsToFields: () => {
        return {
          name: this.$form.createFormField({
            value: ''
          }),
          username: this.$form.createFormField({
            value: ''
          }),
          tel: this.$form.createFormField({
            value: ''
          }),
          password: this.$form.createFormField({
            value: ''
          }),
          confirmPassword: this.$form.createFormField({
            value: null
          }),
          IDcard: this.$form.createFormField({
            value: null
          }),
          addr: this.$form.createFormField({
            value: null
          }),
          desc: this.$form.createFormField({
            value: ''
          }),
          group_id: this.$form.createFormField({
            value: 2
          })
        };
      },
    });
  },
  mounted() { },
  methods: {
    validateTel(rule, value, callback) {
      if (!value) {
        callback('请输入手机号');
      } else if (!(/^1[3456789]\d{9}$/.test(value))) {
        callback('请输入正确的手机号');
      } else {
        callback()
      }
    },

    validateIDcard(rule, value, callback) {
      if (!value) {
        callback('请输入身份证号');
      } else if (!(/(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/.test(value))) {
        callback('请输入正确的身份证号');
      } else {
        callback()
      }
    },

    validatorPassword(rule, value, callback) {
      const form = this.form
      if (!value) {
        callback('请确认密码');
      } else if (value != form.getFieldValue('password')) {
        callback('两次输入的密码不一样');
      } else {
        callback()
      }
    },

    async sub() {
      const that = this
      this.form.validateFields(async (err, value) => {
        if (!err) {
          const form = JSON.parse(JSON.stringify(value))
          const data = {
            name: form.name,
            password: form.password,
            tel: form.tel,
            desc: form.desc,
            addr: form.addr,
            IDcard: form.IDcard,
            group_id: form.group_id
          }
          const r = await this.$axios.post("/user/create", data);
          if (r.status == 1) {
            setTimeout(() => {
              that.$router.push("/daili/list");
            }, 2000);
          }
        }
      });
    },
  },
};
</script>