import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'//引入axios

import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import './assets/main.scss';
import { message } from 'ant-design-vue';

import config from './config';

Vue.prototype.$config = config;
Vue.prototype.API = config.API;
Vue.prototype.ossUrl = config.ossUrl;

Vue.use(Antd)
// ================ axios 配置 ================
axios.defaults.baseURL = config.API;
axios.defaults.rejectUnauthorized = false;
axios.defaults.timeout = 1000 * 60 * 5; //5分钟超时
axios.defaults.headers.post['Content-Type'] = 'application/json;charset=utf-8';
axios.interceptors.request.use(function (config) {
  config.headers.token = store.state.token;
  // if(store.state.token) config.headers.token = store.state.token;
  return config;
}, function (error) {
  console.error(error, 2222222)
});

axios.interceptors.response.use(function (response) {
  if (response.status == 200) {
    // 判断返回数据的状态 以便notification使用
    if (Object.prototype.toString.call(response.data) == '[object Object]') {
      if (!response.data.status) response.data.status = 1;
      switch (response.data.status) {
        case -100:
          response.data.s = 'error';
          message.error('没有权限1')
          router.push({
            path: '/user/login'
          });
          break;
        case -1:
          response.data.s = 'error';
          if (response.data.msg) message.error(response.data.msg);
          break;
        case 0:
          response.data.s = 'warning';
          if (response.data.msg) message.warning(response.data.msg);
          break;
        case 1:
          response.data.s = 'success';
          if (response.data.msg) message.success(response.data.msg);
          break;
        case 2:
          response.data.s = 'info';
          if (response.data.msg) message.info(response.data.msg);
          break;
        default:
          response.data.s = 'success';
          break;
      }
    };
    return response.data
  }
  else {
    return response;
  }
}, function (error) {
  console.error(error, 333444444)
});

Vue.axios = Vue.prototype.$axios = axios;
Vue.config.productionTip = false;

const run = async () => {
  const r = await axios.get('/user/getNavs')
  if (r.status != 1) return;

  // 保存所有导航数组到store
  const navs = r.navs;
  store.commit('setNavs', navs);

  // ----------------- 生成一级菜单 -----------------
  const menus = []; //最终生成的一二级菜单
  navs.forEach(nav => {
    if (nav.class == 1) menus.push(nav)
  });

  // ----------------- 生成二级菜单 -----------------
  for (let menu of menus) {
    menu.children = [];
    navs.forEach(nav => {
      if (nav.father == menu.id && nav.show == 1) menu.children.push(nav)
    })
    if (menu.children.length == 0) delete menu.children;
  }

  // 保存菜单到store
  store.commit('setMenus', menus)
}


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')


// ================ 本地存储用户信息 ================
let user = localStorage.getItem('user');
let token = localStorage.getItem('token');
if (!token || !user) {
  router.push('/user/login')
}
else {
  user = JSON.parse(user);
  store.commit('saveUserData', user);
  store.commit('saveToken', token);
}


if (token) run()