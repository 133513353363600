<template>
  <div class="component-wrap">
    <a-form class="form sec" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
      <h4>承保单位</h4>
      <a-row>
        <a-col :span="12">
          <a-form-item label="承保单位">
            {{ form.chengbaodanwei }}
          </a-form-item>
        </a-col>
      </a-row>

      <hr />
      <h4>保单信息</h4>
      <a-row>
        <a-col :span="12">
          <a-form-item label="订单状态">
            <div v-if="form.status == 0">已删除</div>
            <div v-else-if="form.status == 1">未锁定</div>
            <div v-else-if="form.status == 2">已锁定</div>
            <div v-else-if="form.status == 3">已下载</div>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="保障状态">
            <div v-if="form.isBaozhang">保障中</div>
            <div v-else>未保障</div>
          </a-form-item>
        </a-col>
      </a-row>


      <a-row>
        <a-col :span="12">
          <a-form-item label="电子版合同">
            <a-button style="margin-left: 5px" size="small" @click="xiazaiHetong(form.baodan_url)" type="">
              下载合同
            </a-button>
            <a-button style="margin-left: 5px" size="small" @click="yulanHetong(form.baodan_url)" type="">
              预览合同
            </a-button>
          </a-form-item>
        </a-col>

        <a-col :span="12">
          <a-form-item label="投保时间">
            {{ form.create_time }}
          </a-form-item>
        </a-col>
        <!-- <a-col :span="12">
          <a-form-item label="合同照片">
            <a-button style="margin-left: 5px" size="small" @click="showImgList" type="">
              查看照片
            </a-button>
          </a-form-item>
        </a-col> -->
      </a-row>


      <a-row>
        <a-col :span="12">
          <a-form-item label="创建人">
            {{ form.create_name }}
          </a-form-item>
        </a-col>

        <a-col :span="12">
          <a-form-item label="上级">
            {{ form.shangji_name || '' }}
          </a-form-item>
        </a-col>
      </a-row>

      <a-row v-if="form.update_time">
        <a-col :span="12">
          <a-form-item label="更新时间">
            {{ form.update_time }}
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="更新人">
            {{ form.update_name }}
          </a-form-item>
        </a-col>
      </a-row>

      <a-row>
        <a-col :span="12" v-if="form.pdfUrl">
          <a-form-item label="保单">
            <a-button v-if="!form.pdfUrl.includes('project/')" style="margin-left: 5px" size="small"
              @click="toUrl(ossUrl + '/project' + form.pdfUrl)" type="">
              查看保单
            </a-button>
            <a-button v-else style="margin-left: 5px" size="small" @click="toUrl(ossUrl + form.pdfUrl)" type="">
              查看保单
            </a-button>
          </a-form-item>
        </a-col>

        <a-col :span="12">
          <a-form-item label="标记">
            {{ form.biaojiRemark }}
          </a-form-item>
        </a-col>
      </a-row>

      <a-row>
        <a-col :span="12">
          <a-form-item label="保单号">
            {{ form.baoxiandanhao }}
          </a-form-item>
        </a-col>

        <a-col :span="12">
          <a-form-item label="报案电话">
            {{ form.baoandianhua }}
          </a-form-item>
        </a-col>
      </a-row>


      <hr />
      <h4>投保人基本信息</h4>
      <a-row v-if="form.yyzz_url">
        <a-col :span="12">
          <a-form-item label="营业执照">
            <img v-if="!form.yyzz_url.includes('project/')" style="max-width: 120px; cursor: pointer;" @click="handlePreview(ossUrl + '/project' + form.yyzz_url)"
              :src="ossUrl + '/project' + form.yyzz_url" alt="" srcset="">
            <img v-else style="max-width: 120px; cursor: pointer;" @click="handlePreview(ossUrl + form.yyzz_url)"
              :src="ossUrl + form.yyzz_url" alt="" srcset="">
          </a-form-item>
        </a-col>
      </a-row>

      <a-row>
        <a-col :span="12">
          <a-form-item label="名称(全称)">
            {{ form.tou_name }}
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="社会信用代码">
            {{ form.tou_sn }}
          </a-form-item>
        </a-col>
      </a-row>

      <a-row>
        <a-col :span="12">
          <a-form-item label="所在城市">
            {{ form.tou_city }}
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="联系地址">
            {{ form.tou_addr }}
          </a-form-item>
        </a-col>
      </a-row>

      <hr />
      <h4>投保信息</h4>

      <a-row v-if="form.xsz_url">
        <a-col :span="12">
          <a-form-item label="行驶证">
            <img v-if="!form.xsz_url.includes('project/')" :src="ossUrl + '/project' + form.xsz_url" @click="handlePreview(ossUrl + '/project' + form.xsz_url)"
              style="max-width: 120px; cursor: pointer;" alt="" srcset="">
            <img v-else :src="ossUrl + form.xsz_url" @click="handlePreview(ossUrl + form.xsz_url)"
              style="max-width: 120px; cursor: pointer;" alt="" srcset="">
          </a-form-item>
        </a-col>
      </a-row>

      <a-row>
        <a-col :span="12">
          <a-form-item label="车牌号">
            {{ form.chepai }}
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="车架号">
            {{ form.chejia }}
          </a-form-item>
        </a-col>
      </a-row>

      <a-row>
        <a-col :span="12">
          <a-form-item label="估计雇员人数">
            {{ form.guyuanrenshu }}
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="保险金额">
            {{ form.siwangshangcan }}万元
          </a-form-item>
        </a-col>
      </a-row>

      <a-row>
        <a-col :span="12">
          <a-form-item label="保险费率">
            {{ form.feilv }}‰
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="保险费">
            ￥{{ form.baoxianfei }}
          </a-form-item>
        </a-col>
      </a-row>

      <a-row>
        <a-col :span="12">
          <a-form-item label="每人累计赔偿限额">
            {{ form.leijixiane }}万元
          </a-form-item>
        </a-col>

        <a-col :span="12">
          <a-form-item label="死亡/伤残赔偿责任">
            {{ form.siwangshangcan }}万元
          </a-form-item>
        </a-col>
      </a-row>

      <a-row>
        <a-col :span="12">
          <a-form-item label="误工/医疗赔偿责任">
            {{ form.wugongyiliao }}万元
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="保险期间">
            {{ form.start_at }} ~ {{ form.end_at }}
          </a-form-item>
        </a-col>
      </a-row>

      <a-row>
        <a-col :span="12">
          <a-form-item label="创建时间">
            {{ form.create_time }}
          </a-form-item>
        </a-col>
      </a-row>

      <hr />
      <h4>合同照片</h4>
      <a-row>
        <a-col :span="12">
          <a-form-item label="开票信息">
            <div class="preview-box">
              <div class="preview-img-box" v-for="(item, key) in form.gaozhishuUrl" :key='key'>
                <img v-if="!item.includes('project/')" :src="ossUrl+ '/project' + item" alt="" @click="handlePreview(ossUrl+ '/project' + item)">
                <img v-else :src="ossUrl + item" alt="" @click="handlePreview(ossUrl + item)">
              </div>
            </div>
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="条款">
            <div class="preview-box">
              <div class="preview-img-box" v-for="(item, key) in form.tiaokuanUrl" :key='key'>
                <img v-if="!item.includes('project/')" :src="ossUrl + '/project' + item" alt="" @click="handlePreview(ossUrl + '/project' + item)">
                <img v-else :src="ossUrl + item" alt="" @click="handlePreview(ossUrl + item)">
              </div>
            </div>
          </a-form-item>
        </a-col>
      </a-row>

      <a-row>
        <a-col :span="12">
          <a-form-item label="投保单">
            <div class="preview-box">
              <div class="preview-img-box" v-for="(item, key) in form.toubaodanUrl" :key='key'>
                <img v-if="!item.includes('project/')" :src="ossUrl + '/project' + item" alt="" @click="handlePreview(ossUrl + '/project' + item)">
                <img v-else :src="ossUrl + item" alt="" @click="handlePreview(ossUrl + item)">
              </div>
            </div>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>

    <!-- 提交按钮 -->
    <div class="buttons">
      <a-button type="primary" v-if="form.status == 1" @click="edit">编辑</a-button>
      <a-button type="primary" v-else @click="goBack">返回</a-button>
      <a-button type="primary" v-if="form.status == 1" @click="showShangchuan">上传</a-button>

    </div>
    <WordYulan v-if="yulanData.show" :wordUrl="yulanData.url" @callback="closeYulan" />
    <UploadPicMultModel v-if="shangchuan.show" @updateCallBack="updateCallBack"
      :oldData="{ gaozhishuUrl: uploadRecord.gaozhishuUrl, tiaokuanUrl: uploadRecord.tiaokuanUrl, toubaodanUrl: uploadRecord.toubaodanUrl }" />

    <UploadPicMultModelPreview v-if="picMultModelPreview.show" @updateCallBack="updatePreviewCallBack"
      :oldData="{ gaozhishuUrl: uploadRecord.gaozhishuUrl, tiaokuanUrl: uploadRecord.tiaokuanUrl, toubaodanUrl: uploadRecord.toubaodanUrl }" />

    <a-modal :visible="preview.previewVisible" :footer="null" @cancel="handleCancel">
      <img alt="example" style="width: 100%" :src="preview.previewImage" />
    </a-modal>

  </div>
</template>

<style lang='scss' scoped>
hr {
  border-top: none;
  margin-bottom: 30px;
}

h4 {
  margin-left: 25px;
  color: #1785ff;
}
</style>

<script>
import WordYulan from '../../components/wordYulan.vue';
import UploadPicMultModel from '../../components/uploadPicMultModel.vue';
import UploadPicMultModelPreview from '../../components/UploadPicMultModelPreview.vue';

export default {
  components: { WordYulan, UploadPicMultModel, UploadPicMultModelPreview },
  data() {
    return {
      form: {},
      id: "",
      yulanData: {
        show: false,
        url: ''
      },
      preview: {
        previewImage: '',
        previewVisible: false
      },
      previewList: {
        previewVisible: false,
        list: []
      },
      shangchuan: {
        show: false
      },
      picMultModelPreview: {
        show: false
      }
    };
  },
  async created() {
    this.id = this.$route.query.id;
    await this.getDetail()

  },
  methods: {
    async getDetail() {
      const r = await this.$axios.get("/order/getOrderInfo?id=" + this.id);
      if (r.status == 1) {
        this.form = r.info;
      }
    },
    edit() {
      this.$router.push("/order/edit?id=" + this.form.id);
    },
    toUrl(url) {
      window.open(url)
    },
    xiazaiHetong(url) {
      if (!url.includes('project/')) {
        window.open(this.ossUrl + '/project' + url)
      } else {
        window.open(this.ossUrl + url)
      }
    },
    yulanHetong(url) {
      this.yulanData = {
        show: true,
        url: url.includes('project/') ? this.ossUrl + url : this.ossUrl + '/project' + url
      }
    },
    closeYulan() {
      this.yulanData = {
        show: false,
        url: ''
      }
    },

    async handlePreview(file) {
      this.preview.previewImage = file
      this.preview.previewVisible = true
    },
    handleCancel() {
      this.preview.previewVisible = false
      this.preview.previewImage = null
    },
    goBack() {
      this.$router.go(-1)
    },
    initRecordData() {
      const record = this.form
      this.uploadRecord = JSON.parse(JSON.stringify(record))
      this.uploadRecord.gaozhishuUrl = record.gaozhishuUrl
      this.uploadRecord.tiaokuanUrl = record.tiaokuanUrl
      this.uploadRecord.toubaodanUrl = record.toubaodanUrl
    },
    async showShangchuan() {
      this.initRecordData()
      this.shangchuan.show = true
      // this.$message.error('尚未完成')
    },
    async updateCallBack(e) {
      const that = this
      this.shangchuan.show = false
      if (e) {
        // 直接把e传到后台保存到数据库
        const r = await this.$axios.post("/order/updateTupian", {
          id: that.uploadRecord.id,
          data: e
        })
        this.getDetail()
      }
      this.uploadRecord = {}
    },

    updatePreviewCallBack() {
      this.picMultModelPreview.show = false
      this.uploadRecord = {}
    },
    showImgList() {
      this.initRecordData()
      this.picMultModelPreview.show = true
    },
    getImgUrl(i) {
      return `${this.API}${this.previewList.list[i]}`;
    },
    handleCancelList() {
      this.previewList.list = []
      this.previewList.previewVisible = false
    },
    async doTest() {
      const r = await this.$axios.post("/file/parsePdf", {
      })
    }
  },
};
</script>