<template>
  <div class="page">
    <a-form class="ctrl" layout="inline">
      <a-form-item>
        <a-button type="primary" @click="show_create = true">添加</a-button>
      </a-form-item>
    </a-form>

    <a-table
      class="sec"
      size="small"
      :rowKey="(record) => record.id"
      bordered
      :columns="columns"
      :data-source="list"
      :pagination="false"
    >

      <template slot="group_name" slot-scope="text, record">
        <a-tag>{{record.group_name}}</a-tag>
      </template>

      <template slot="status" slot-scope="text, record">
        <a-tag v-if="record.status == 1" color="green">正常</a-tag>
        <a-tag v-if="record.status == 0" color="red">已删除</a-tag>
      </template>

      <template slot="options" slot-scope="text, record">
        <!-- <a-button type="primary" style="margin-right: 10px" size="small" @click="update(record)">查看</a-button> -->

        <a-button type="primary" style="margin-right: 10px" size="small" @click="update(record)">编辑</a-button>

        <a-popconfirm
          title="密码将重置为用户手机号"
          ok-text="确定"
          cancel-text="取消"
          @confirm="resetPsw(record.who)"
        >
          <a-button style="margin-right: 10px" size="small">重置密码</a-button>
        </a-popconfirm>

        <a-popconfirm
          v-if="record.status == 1"
          title="确定删除吗？"
          ok-text="确定"
          cancel-text="取消"
          @confirm="updateStatus(record.id, 0)"
        >
          <a-button type="danger" size="small">删除</a-button>
        </a-popconfirm>

        <a-popconfirm
          v-if="record.status == 0"
          title="确定恢复吗？"
          ok-text="确定"
          cancel-text="取消"
          @confirm="updateStatus(record.id, 1)"
        >
          <a-button type="primary" size="small">恢复</a-button>
        </a-popconfirm>
      </template>
    </a-table>

    <Create v-if="show_create" @callback="createCb" />
    <Update v-if="updateModal.show" :d="updateModal.data" @callback="updateCb" />
  </div>
</template>

<style lang='scss' scoped>
</style>

<script>
import Create from "./create.vue";
import Update from "./update.vue";

const columns = [
  {
    title: "权限",
    scopedSlots: { customRender: "group_name" },
  },
  {
    title: "姓名",
    dataIndex: "name",
  },
  {
    title: "用户名",
    dataIndex: "username",
  },
  {
    title: "手机",
    dataIndex: "tel",
  },
  {
    title: "备注",
    dataIndex: "desc",
  },
  {
    title: "状态",
    scopedSlots: { customRender: "status" },
  },
  {
    title: "操作",
    scopedSlots: {
      customRender: "options",
    },
  },
];

export default {
  components: { Create, Update },
  data() {
    return {
      columns,
      list: [],
      show_create: false,
      updateModal: {
        show: false,
        data: {}
      }
    };
  },
  created() {
    this.getList();
  },
  methods: {
    // 添加回调
    createCb(e) {
      if (e) this.getList();
      this.show_create = false;
    },

    // 编辑
    update (data) {
      this.updateModal = {
        show: true,
        data
      }
    },
    // 编辑回调
    updateCb (e) {
      if (e) this.getList();
      this.updateModal = {
        show: false,
        data: {}
      }
    },

    async getList() {
      const r = await this.$axios.get("/user/getList");
      console.log(r);
      if(r.status == 1) this.list = r.list;
    },

    async updateStatus(id, status) {
      const r = await this.$axios.post("/user/updateStatus", {
        id: id,
        status: status,
      });
      if (r.status == 1) {
        this.getList();
      }
    },

    resetPsw (who) {
      this.$axios.post("/user/resetPsw", {
        who: who,
      });
    }
  },
};
</script>