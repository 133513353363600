<template>
    <div class="component-wrap">
        <a-form class="form sec" :label-col="{ span: 10 }" :wrapper-col="{ span: 10 }">

            <a-form-item label="行驶证">
                <img :src="form.image" @click="handlePreview(form.image)" style="max-width: 120px; cursor: pointer;"
                    alt="" srcset="">
            </a-form-item>
            <a-form-item label="车牌号">
                {{ form.chepai }}
            </a-form-item>

            <a-form-item label="车架号">
                {{ form.chejia }}
            </a-form-item>
        </a-form>
        <div class="buttons">
            <a-button v-if="$store.state.user.group_id != 2" type="primary" @click="toEdit">编辑</a-button>
            <a-button v-else type="primary" @click="goBack">返回</a-button>
        </div>

        <a-modal :visible="preview.previewVisible" :footer="null" @cancel="handleCancel">
            <img alt="example" style="width: 100%" :src="preview.previewImage" />
        </a-modal>
    </div>
</template>

<style lang='scss' scoped>
hr {
    border-top: none;
    margin: 40px;
}

h4 {
    margin-left: 20vw;
}
</style>

<script>
export default {
    data() {
        return {
            id: null,
            form: {
                image: null
            },
            preview: {
                previewImage: '',
                previewVisible: false
            }
        };
    },
    async created() {
        this.id = this.$route.query.id;
        const r = await this.$axios.get("/car/getInfo?id=" + this.id);
        if (r.status != 1) return
        this.form = r.info
        if(!this.form.image.includes('project/')) {
            this.form.image = this.ossUrl + '/project' + this.form.image
        }else{
            this.form.image = this.ossUrl + this.form.image
        }
    },
    mounted() { },
    methods: {
        async toEdit() {
            this.$router.push('/car/update?id=' + this.id)
        },
        async handlePreview(file) {
            this.preview.previewImage = file
            this.preview.previewVisible = true
        },
        handleCancel() {
            this.preview.previewVisible = false
            this.preview.previewImage = null
        },
        goBack() {
            this.$router.go(-1)
        }
    },
};
</script>