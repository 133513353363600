import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    collapsed: false, //折叠导航
    user: {}, //用户信息
    token: '',
    navs: [], //数据库中获取到的所有路由数组
    menus: [], //导航菜单 一、二级
    activeMenu: {
      selectedKeys: [],
      openKeys: [],
      breadcrumbs: []
    },
    activeNav: 'index', //当前选中的导航页面
    keepRoutes: [], //缓存的routes
  },
  mutations: {
    // ------- 登录用户信息 -------
    saveData (state, data) {
      state.token = data.token.token;
      state.user = data.user;
      localStorage.setItem('user', JSON.stringify(data.user));
      localStorage.setItem('token', data.token.token);
    },
    saveUserData (state, data) {
      state.user = data;
    },
    saveToken(state, token){
      state.token = token;
    },
    // ------- 导航菜单高亮 -------
    collapse (state) {
      state.collapsed = !state.collapsed
    },
    setMenus (state, data) {
      state.menus = data
    },
    setNavs (state, data) {
      state.navs = data
    },
    setActiveMenu (state, data) {
      state.activeMenu = data;
    },
    saveKeepRoutes(state, routes) {
      const pathArr = []
      for (let item of routes) {
        pathArr.push(item.name)
      }
      state.keepRoutes = pathArr
      console.log('state.keepRoutes-----------')
      console.log(state.keepRoutes)
    },




  },
  actions: {
  },
  modules: {
  }
})
