<template>
    <a-modal title="查看照片" okText="确定" :visible="true" cancelText="取消" @ok="updateCallBack" @cancel="callback" width="1000px"
        :maskClosable="false">
        <div>开票信息</div>
        <uploadPicMultPreview @callbackPic="gaozhishuCallBack" :folder="'gaozhishu'" :oldData="gaozhishuData"/>
        <div>条款</div>
        <uploadPicMultPreview @callbackPic="tiaokuanCallBack" :folder="'tiaokuan'" :oldData="tiaokuanData"/>
        <div>投保单</div>
        <uploadPicMultPreview @callbackPic="toubaodanCallBack" :folder="'toubaodan'" :oldData="toubaodanData" />
    </a-modal>
</template>

<style lang='scss' scoped></style>
<script>
import uploadPicMultPreview from './uploadPicMultPreview'
export default {
    components: { uploadPicMultPreview },
    props: ['oldData'],
    data() {
        return {
            location: {},
            gaozhishuData: [],
            tiaokuanData: [],
            toubaodanData: []
        };
    },
    watch:{
        oldData() {
            this.gaozhishuData = this.oldData.gaozhishuUrl
            this.tiaokuanData = this.oldData.tiaokuanUrl
            this.toubaodanData = this.oldData.toubaodanUrl
        }
    },
    created() {
        this.gaozhishuData = this.oldData.gaozhishuUrl
        this.tiaokuanData = this.oldData.tiaokuanUrl
        this.toubaodanData = this.oldData.toubaodanUrl
    },
    mounted() {

    },
    methods: {
        callback() {
            this.$emit('updateCallBack')
        },
        gaozhishuCallBack(e) {
            this.gaozhishuData = e
        },
        tiaokuanCallBack(e) {
            this.tiaokuanData = e
        },
        toubaodanCallBack(e) {
            this.toubaodanData = e
        },
        updateCallBack() {
            const data = {
                gaozhishu: this.gaozhishuData,
                tiaokuanData: this.tiaokuanData,
                toubaodanData: this.toubaodanData
            }
            this.$emit('updateCallBack', data)
        }
    },
};
</script>