var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"component-wrap"},[_c('a-form',{staticClass:"form sec",attrs:{"form":_vm.form,"label-col":{ span: 10 },"wrapper-col":{ span: 10 }}},[_c('a-form-item',{attrs:{"label":"行驶证"}},[_c('UploadPic',{attrs:{"folder":'xingshizheng',"type":'xingshizheng',"oldUrl":_vm.xszUrl},on:{"callbackPic":_vm.xszCallback}})],1),_c('a-form-item',{attrs:{"label":"车牌号"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['chepai', {
        rules: [
          {
            required: true,
            message: '请输入车牌号'
          }]
      }]),expression:"['chepai', {\n        rules: [\n          {\n            required: true,\n            message: '请输入车牌号'\n          }]\n      }]"}],staticStyle:{"width":"60%"},attrs:{"placeholder":"请输入车牌号"}})],1),_c('a-form-item',{attrs:{"label":"车架号"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['chejia', {
        rules: [
          {
            required: true,
            message: '请输入车架号'
          }]
      }]),expression:"['chejia', {\n        rules: [\n          {\n            required: true,\n            message: '请输入车架号'\n          }]\n      }]"}],staticStyle:{"width":"60%"},attrs:{"placeholder":"请输入车架号"}})],1)],1),_c('div',{staticClass:"buttons"},[_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.sub}},[_vm._v("提交")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }