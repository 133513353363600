<template>
    <div class="page">
      <a-form class="ctrl" layout="inline">
  
        <a-form-item label="车牌号">
          <a-input style="width: 200px" placeholder="请输入车牌号" v-model="options.chepai"></a-input>
        </a-form-item>
        <a-form-item label="车架号">
          <a-input style="width: 200px" placeholder="请输入车架号" v-model="options.chejia"></a-input>
        </a-form-item>
  
        <a-form-item>
          <a-button type="primary" @click="search">搜索</a-button>
        </a-form-item>
      </a-form>
  
      <div class="statistics">共{{ page.amount }}个</div>
      <a-table class="sec" size="small" :rowKey="(record) => record.id" bordered :columns="columns" :data-source="list"
        :pagination="false">
  
        <template slot="action" slot-scope="text, record">
  
          <a-button style="margin: 5px" size="small" type="" @click="goto('/car/detail?id=' + record.id)">详情</a-button>
          <a-button size="small" v-if="![2,4].includes($store.state.user.group_id)" type="primary" @click="goto('/car/update?id=' + record.id)">编辑</a-button>
  
          <a-popconfirm title="确定要删除吗" v-if="record.status == 1 && ![2,4].includes($store.state.user.group_id)" @confirm="updateStatus(record.id, 0)" okText="确定" cancelText="取消">
            <a-button style="margin: 5px" size="small" type="danger">删除</a-button>
          </a-popconfirm>
  
          <a-button style="margin: 5px" size="small" type="" @click="gotoDingdan(record.chepai)">查询订单</a-button>
        </template>
      </a-table>
  
      <a-pagination class="pagination" showQuickJumper v-model="page.now_page" :defaultPageSize="50" :total="page.amount"
        @change="getList" />
    </div>
  </template>
  
  <style lang='scss' scoped>
  </style>
  
  <script>
  
    const columns = [
      {
        title: "#",
        dataIndex: "id",
      },
      {
        title: "车牌",
        dataIndex: "chepai",
      },
      {
        title: "车架",
        dataIndex: "chejia",
      },
      {
        title: "添加时间",
        dataIndex: "create_time",
      },
      {
        title: "操作",
        scopedSlots: {
          customRender: "action",
        },
      },
    ];
  
    export default {
      name: 'carList',
      data() {
        return {
          columns,
          list: [],
          options: {
            chepai: '',
            chejia: ''
          },
          page: {
            now_page: 1,
            page_size: 50,
            amount: 0,
          },
        };
      },
      created() {
        this.getList();
      },
      methods: {
        search() {
          this.page.now_page = 1;
          this.getList();
        },
  
        async getList() {
          const options = { ...this.options };
          options.nowPage = this.page.now_page;
          const r = await this.$axios.post("/car/getList", options);
          this.list = r.list;
          this.page.amount = r.amount;
        },
  
        goto(url) {
             // 打开新窗口
            // let routeData = this.$router.resolve({ path: url });
            // window.open(routeData.href, '_blank');
            this.$router.push(url);
        },
        gotoDingdan(chepai) {
          if(this.$store.state.user.group_id == 2) {
            this.$router.push('/order/myList?chepai=' + chepai);
          }else{
            this.$router.push('/order/list?chepai=' + chepai);
          }
          
        },
        async updateStatus(id, status) {
          const r = await this.$axios.post("/car/updateStatus", {
            id,
            status,
          });
  
          if (r.status == 1) this.getList();
        }
      },
    };
  </script>