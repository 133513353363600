<template>
    <div class="component-wrap">
        <a-form :form="form" class="form sec" :label-col="{ span: 10 }" :wrapper-col="{ span: 10 }">

            <a-form-item label="营业执照">
                <UploadPic :folder="'yingyezhizhao'" :type="'yingyezhizhao'" :oldUrl="yyzzUrl"
                    @callbackPic="yyzzCallback" />
            </a-form-item>


            <a-form-item label="名称(全称)">
                <a-input style="width: 60%" placeholder="请输入名称(全称)" v-decorator="['tou_name', {
            rules: [
                {
                    required: true,
                    message: '请输入名称(全称)'
                }]
        }]"></a-input>
            </a-form-item>

            <a-form-item label="社会信用代码">
                <a-input style="width: 60%" placeholder="请输入社会信用代码" v-decorator="['tou_sn', {
            rules: [
                {
                    required: true,
                    message: '请输入社会信用代码'
                }]
        }]"></a-input>
            </a-form-item>

            <a-form-item label="所在城市">
                <a-input style="width: 60%" placeholder="请输入所在城市" v-decorator="['tou_city', {
            rules: [
                {
                    required: true,
                    message: '请输入所在城市'
                }]
        }]"></a-input>
            </a-form-item>

            <a-form-item label="联系地址">
                <a-textarea style="width: 60%" placeholder="请输入联系地址" v-decorator="['tou_addr']" />
            </a-form-item>
        </a-form>
        <div class="buttons">
            <a-button type="primary" @click="sub">提交</a-button>
        </div>
    </div>
</template>

<style lang='scss' scoped>
hr {
    border-top: none;
    margin: 40px;
}

h4 {
    margin-left: 20vw;
}
</style>

<script>
import UploadPic from '../../components/uploadPic.vue';
export default {
    name: 'customerUpdate',
    components: { UploadPic },
    data() {
        return {
            id: null,
            form: null,
            yyzzUrl: ''
        };
    },

    // activated() {
    //     this.init()
    // },
    deactivated() {
    },
    async created() {
        await this.init()
    },
    mounted() {
    },
    methods: {
        yyzzCallback(d) {
            this.yyzzUrl = d.file_path
        },

        async init() {
            this.id = this.$route.query.id;
            const r = await this.$axios.get("/customer/getInfo?id=" + this.id);
            if (r.status != 1) return
            this.yyzzUrl = r.info.image
            this.form = this.$form.createForm(this, {
                name: 'carForm',
                mapPropsToFields: () => {
                    return {
                        tou_name: this.$form.createFormField({
                            value: r.info.tou_name
                        }),
                        tou_sn: this.$form.createFormField({
                            value: r.info.tou_sn
                        }),
                        tou_city: this.$form.createFormField({
                            value: r.info.tou_city
                        }),
                        tou_addr: this.$form.createFormField({
                            value: r.info.tou_addr
                        })
                    };
                },
            });
        },
        async sub() {
            const that = this

            if (!that.yyzzUrl) {
                this.$message.error('请上传营业执照！')
                return
            }
            this.form.validateFields(async (err, value) => {
                if (!err) {
                    const form = JSON.parse(JSON.stringify(value))
                    const data = {
                        id: that.id,
                        tou_name: form.tou_name,
                        tou_sn: form.tou_sn,
                        tou_city: form.tou_city,
                        tou_addr: form.tou_addr,
                        image: that.yyzzUrl
                    }
                    const r = await this.$axios.post("/customer/update", data);
                    if (r.status == 1) {
                        setTimeout(() => {
                            that.$router.push("/customer/detail?id=" + that.id)
                        }, 2000);
                    }
                }
            });
        },
    },
};
</script>