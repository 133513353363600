<template>
    <a-modal
      title="文件预览"
      okText="确定"
      :visible="true"
      cancelText="取消"
      @ok="callback"
      @cancel="callback"
      width="1000px"
      :maskClosable="false"
    >
        <iframe :src="'https://view.officeapps.live.com/op/view.aspx?src=' + wordUrl" style="width: 100%;height: 600px" />
    </a-modal>
  </template>
  
  <style lang='scss' scoped>
  </style>
  
  <script>
  export default {
    props: [ 'wordUrl' ],
    data() {
      return {
        location: {}
      };
    },
    created() {
    },
    mounted() {
        // this.wordUrl = 'https://hengfeng.6had.com/yulan.docx'
    },
    methods: {
      callback () {
        this.$emit('callback')
      },
    },
  };
  </script>