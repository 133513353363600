<template>
    <a-upload-dragger name="file" :multiple="mult" :action="route" :headers="headers" @change="handleChange"
        :show-upload-list="false" :before-upload="beforeUpload" :data="data">
        <p class="ant-upload-drag-icon">
            <a-icon type="inbox" />
        </p>
        <p class="ant-upload-text">
            点击或者拖拽文件到此区域上传
        </p>
        <!-- <p class="ant-upload-hint">
        Support for a single or bulk upload. Strictly prohibit from uploading company data or other
        band files
        </p> -->
    </a-upload-dragger>
</template>

<script>
import * as imageConversion from 'image-conversion'
export default {
    props: ["mult", "folder", "oldUrl", "allow", "type"],
    data() {
        return {
            route: this.API + "/file/uploadFile",
            headers: {
                token: "",
            },
            data: {},
            fileList: [],
            imageUrl: '',
            loading: false,
            tupianleixing: ''
        };
    },
    watch: {
        // oldFiles() {
        //     if (this.oldFiles.length > 0) {
        //         this.renderFiles();
        //     }
        // },
        oldUrl() {
            // 判断一下是否是老数据兼容老版本API
            if(!this.oldUrl.includes('project/')) {
                this.imageUrl = this.ossUrl + '/project' + this.oldUrl
            } else {
                this.imageUrl = this.ossUrl + this.oldUrl
            }
        }
    },
    created() {
        if (this.oldUrl) {
            // 判断一下是否是老数据兼容老版本API
            if(!this.oldUrl.includes('project/')) {
                this.imageUrl = this.ossUrl + '/project' + this.oldUrl
            } else {
                this.imageUrl = this.ossUrl + this.oldUrl
            }
        }
        this.tupianleixing = this.type == 'yingyezhizhao' ? '营业执照' : '行驶证'
        this.headers.token = this.$store.state.token;
        this.data.folder = this.folder || "other";
    },
    methods: {
        async handleChange(info) {
            let files = [];
            if (info.fileList.length) {
                let fileList = [info.fileList[info.fileList.length - 1]];
                // 2. read from response and show file link
                fileList = fileList.map((file) => {
                    if (file && file.response) {
                        // Component will show file.url as link
                        file.url = file.response.url;
                    }
                    return file;
                });
                this.fileList = fileList;

                for (let item of this.fileList) {
                    if (item.status == "done" && item.response.status == 1) {
                        files = [
                            {
                                file_path: item.response.file_path,
                                file_name: item.response.fileObj.base,
                                fileObj: item.response.fileObj,
                            },
                        ];
                    }
                }
            } else {
                this.fileList = [];
            }

            if (["done", "removed"].includes(info.file.status)) {
                // console.log("--------- 上传成功的文件 ---------");
                // console.log(files);
                this.imageUrl = this.ossUrl + files[0].file_path
                this.$emit("callbackPic", files[0]);
            }
        },
        beforeUpload(file) {
            const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
            if (!isJpgOrPng) {
                this.$message.error('只能上传jpg和png格式图片!');
                return false
            }
            const isLt10M = file.size / 1024 / 1024 < 15;
            if (!isLt10M) {
                this.$message.error('图片大小不能超过15MB!');
                return false
            }
            return new Promise((resolve, reject) => {
                if (file.size / 1024 / 1024 > 5) {
                    imageConversion.compressAccurately(file, 5000).then(res => {
                        res.lastModifiedDate = new Date(); // 文件最后的修改日期
                        res.name = file.name; // 文件名
                        const yasuoFile = new File([res], file.name, { type: res.type, lastModified: Date.now() });
                        resolve(yasuoFile)
                    })
                } else {
                    resolve(file)
                }
            })
        }
    },
};
</script>

<style lang="scss" scoped>
.ant-upload-text {
    font-size: 14px;
}
</style>