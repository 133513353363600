<template>
    <div class="component-wrap">
        <a-form class="form sec" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
            <a-row>
                <a-col :span="24">
                    <div style="width: 100%;display: flex; align-items: center; justify-content: center;">
                        <div style="width: 500px;" :key="uploadKey">
                            <uploadPDFDragger :folder="'fapiao'" :mult="true" @callbackPdf="callbackPdf" />
                        </div>
                    </div>
                </a-col>
            </a-row>

            <a-divider :dashed="true">识别失败</a-divider>
            <a-table class="sec" size="small" :rowKey="(record) => shibieResult.failList.indexOf(record)" bordered :columns="columns"
                :data-source="shibieResult.failList" :pagination="false">
                <template slot="kehumingcheng" slot-scope="text, record">
                    {{ record.kehumingcheng || '' }}
                </template>

                <template slot="kaipiaoriqi" slot-scope="text, record">
                </template>

                <template slot="jine" slot-scope="text, record">
                </template>

                <template slot="action" slot-scope="text, record">
                    <a-button @click="yulanHetong(record.file_path)" style="margin-left: 5px" size="small"
                        type="">
                        查看
                    </a-button>
                </template>
            </a-table>

            <a-divider :dashed="true">识别数据</a-divider>
            <div style="padding-left: 30px;">共{{ shibieResult.successList.length }}条</div>
            <a-table class="sec" size="small" :rowKey="(record) => shibieResult.successList.indexOf(record)" bordered :columns="columns"
                :data-source="shibieResult.successList" :pagination="false">
                <template slot="kehumingcheng" slot-scope="text, record">
                    {{ record.kehumingcheng || '' }}
                </template>

                <template slot="kaipiaoriqi" slot-scope="text, record">
                    {{ record.kaipiaoriqi || '' }}
                </template>

                <template slot="jine" slot-scope="text, record">
                    {{ record.heiji || '' }}
                </template>
                <template slot="action" slot-scope="text, record">
                    <a-button @click="yulanHetong(record.file_path)" style="margin-left: 5px" size="small"
                        type="">
                        查看
                    </a-button>
                    <a-button @click="showEdit(record)" style="margin-left: 5px" size="small"
                        type="primary">
                        编辑
                    </a-button>
                    <a-popconfirm title="确定要删除吗"  @confirm="del(record)" okText="确定" cancelText="取消">
                        <a-button type="danger" style="margin-left: 5px" size="small">
                            删除
                        </a-button>
                    </a-popconfirm>
                </template>
            </a-table>

        </a-form>
        <!-- 提交按钮 -->
        <div class="buttons">
            <a-button type="primary" @click="doSave">保存</a-button>
        </div>

        <a-modal title="编辑" :visible="singlePdfData.show" @cancel="cancelSinglePdfData" @ok="subSinglePdfData">
            <a-form class="ctrl" :label-col="{ span: 4 }" :wrapper-col="{ span: 18 }">
                <a-form-item label="客户名称">
                    <a-input v-model="singlePdfData.kehumingcheng"></a-input>
                </a-form-item>
                <a-form-item label="开票日期">
                    <a-date-picker v-model="singlePdfData.kaipiaoriqi" />
                </a-form-item>
                <a-form-item label="金额">
                    <a-input type="number" v-model="singlePdfData.heiji"></a-input>
                </a-form-item>
            </a-form>
        </a-modal>

    </div>
</template>

<script>
import uploadPDFDragger from '../../components/uploadPDFDragger.vue';
import moment from 'moment'
const columns = [

    {
        title: "客户名称",
        scopedSlots: {
            customRender: "kehumingcheng",
        },
    },

    {
        title: "开票日期",
        scopedSlots: {
            customRender: "kaipiaoriqi",
        },
    },
    {
        title: "金额",
        scopedSlots: {
            customRender: "jine",
        },
    },
    {
        title: "操作",
        scopedSlots: {
            customRender: "action",
        },
    },
];

export default {
    name: 'fapiaoShibie',
    components: { uploadPDFDragger },
    data() {
        return {
            columns,
            shibieResult: {
                failList: [],
                successList: []
            },
            yulanData: {
                show: false,
                url: ''
            },
            uploadKey: Date.now(),
            singlePdfData: {
                show: false
            }
        };
    },
    async created() {
    },
    methods: {
        callbackPdf(d) {
            this.shibieResult = d
            console.log(d)
        },
        shibie() {
            console.log('开始识别...')
        },
        yulanHetong(url) {
            if(!url.includes('project/')) {
                window.open(this.ossUrl + '/project' + url)
            }else{
                window.open(this.ossUrl + url)
            }
        },
        jumpUrl(url){
            this.$router.push(url)
        },
        async ceshi() {
            const r = await this.$axios.post('/file/parsePdf')
        },
        async doSave() {
            const that = this
            if(this.shibieResult.successList.length == 0) {
                this.$message.error('没有识别成功的发票..')
                return
            }
            const r = await that.$axios.post('/fapiao/createFapiao', {
                params: this.shibieResult.successList
            })
            if(r.status == 1) {
                that.uploadKey = Date.now()
                that.shibieResult = {
                    failList: [],
                    successList: []
                }
                that.$router.push('/fapiao/list')
            }
        },
        showEdit(record) {
            const index = this.shibieResult.successList.indexOf(record)
            this.singlePdfData = JSON.parse(JSON.stringify(record))
            this.singlePdfData.kaipiaoriqi = moment(this.singlePdfData.kaipiaoriqi, "YYYY-MM-DD")
            this.singlePdfData.index = index
            this.singlePdfData.show = true
        },
        cancelSinglePdfData() {
            this.singlePdfData = {
                show: false
            }
        },
        subSinglePdfData() {
            if(!this.singlePdfData.kehumingcheng){
                this.$message.error('请填写客户名称')
                return
            }
            if(!this.singlePdfData.kaipiaoriqi){
                this.$message.error('请选择开票日期')
                return
            }
            if(this.singlePdfData.heiji <= 0){
                this.$message.error('请填写正确的合计')
                return
            }
            this.shibieResult.successList[this.singlePdfData.index].kehumingcheng = this.singlePdfData.kehumingcheng
            this.shibieResult.successList[this.singlePdfData.index].kaipiaoriqi = moment(this.singlePdfData.kaipiaoriqi).format("YYYY年MM月DD日")
            this.shibieResult.successList[this.singlePdfData.index].heiji = this.singlePdfData.heiji
            this.cancelSinglePdfData()
        },
        del(record) {
            const index = this.shibieResult.successList.indexOf(record)
            this.shibieResult.successList.splice(index, 1)
        }
    },
};
</script>
<style>
.dynamic-delete-button {
    cursor: pointer;
    position: relative;
    top: 4px;
    font-size: 24px;
    color: #999;
    transition: all 0.3s;
}

.dynamic-delete-button:hover {
    color: #777;
}

.dynamic-delete-button[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
}

.shibie-bottom {
    display: flex;
    min-width: 0;

    .left {
        flex: 1;
        min-width: 0;

    }

    .right {
        flex: 1;
        min-width: 0;

    }
}
</style>