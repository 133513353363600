import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)
import axios from 'axios'
import store from '../store/index'

const routes = [
  // =================== 首页 ===================
  {
    path: '/',
    name: 'index',
    meta: {
      title: '首页'
    },
    component: require('@/pages/index').default
  },

  // =================== 车辆管理 ===================
  {
    path: '/car/list',
    name: 'carList',
    meta: {
      title: '车辆列表'
    },
    component: require('@/pages/car/list').default
  },

  {
    path: '/car/update',
    name: 'carUpdate',
    meta: {
      title: '编辑车辆'
    },
    component: require('@/pages/car/update').default
  },

  {
    path: '/car/detail',
    name: 'carDetail',
    meta: {
      title: '车辆详情'
    },
    component: require('@/pages/car/detail').default
  },

  // =================== 订单管理 ===================
  {
    path: '/order/create',
    name: 'orderCreate',
    meta: {
      title: '创建订单'
    },
    component: require('@/pages/order/create').default
  },
  {
    path: '/order/list',
    name: 'orderList',
    meta: {
      title: '订单列表'
    },
    component: require('@/pages/order/list').default
  },
  {
    path: '/order/myList',
    name: 'myOrderList',
    meta: {
      title: '我的订单'
    },
    component: require('@/pages/order/myList').default
  },
  
  {
    path: '/order/info',
    name: 'orderInfo',
    meta: {
      title: '订单详情'
    },
    component: require('@/pages/order/info').default
  },
  {
    path: '/order/edit',
    name: 'orderEdit',
    meta: {
      title: '编辑订单'
    },
    component: require('@/pages/order/edit').default
  },


  // =================== 员工管理 ===================
  {
    path: '/daili/create',
    name: 'dailiCreate',
    meta: {
      title: '添加员工'
    },
    component: require('@/pages/daili/create').default
  },
  {
    path: '/daili/list',
    name: 'dailiList',
    meta: {
      title: '员工列表'
    },
    component: require('@/pages/daili/list').default
  },
  {
    path: '/daili/detail',
    name: 'dailiDetail',
    meta: {
      title: '员工详情'
    },
    component: require('@/pages/daili/detail').default
  },
  {
    path: '/daili/update',
    name: 'dailiUpdate',
    meta: {
      title: '编辑员工'
    },
    component: require('@/pages/daili/update').default
  },

  // =================== 客户管理 ===================

  {
    path: '/customer/list',
    name: 'customerList',
    meta: {
      title: '客户管理'
    },
    component: require('@/pages/customer/list').default
  },

  {
    path: '/customer/update',
    name: 'customerUpdate',
    meta: {
      title: '编辑客户'
    },
    component: require('@/pages/customer/update').default
  },
  {
    path: '/customer/detail',
    name: 'customerDetail',
    meta: {
      title: '客户详情'
    },
    component: require('@/pages/customer/detail').default
  },
  // =================== user ===================
  {
    path: '/user/list',
    name: 'userList',
    meta: {
      title: '用户列表'
    },
    component: require('@/pages/user/list').default
  },
  {
    path: '/user/group',
    name: 'userGroup',
    meta: {
      title: '权限分组'
    },
    component: require('@/pages/user/group').default
  },
  {
    path: '/user/login',
    name: 'login',
    meta: {
      layout: 'simple'
    },
    component: require('@/pages/user/login').default
  },
  {
    path: '/user/updatePsw',
    name: 'updatePsw',
    meta: {
      title: '修改密码'
    },
    component: require('@/pages/user/updatePsw').default
  },

  {
    path: '/user/info',
    name: 'userInfo',
    meta: {
      title: '个人资料'
    },
    component: require('@/pages/user/info').default
  },
  {
    path: '/setting/setting',
    name: 'settingPage',
    meta: {
      title: '配置管理'
    },
    component: require('@/pages/setting/setting').default
  },
  {
    path: '/shibie/pdf',
    name: 'shibiePdf',
    meta: {
      title: '识别PDF'
    },
    component: require('@/pages/shibie/pdf').default
  },
  {
    path: '/fapiao/shibie',
    name: 'fapiaoShibie',
    meta: {
      title: '发票识别'
    },
    component: require('@/pages/fapiao/shibie').default
  },
  {
    path: '/fapiao/list',
    name: 'fapiaoList',
    meta: {
      title: '发票列表'
    },
    component: require('@/pages/fapiao/list').default
  },
  {
    path: '/fapiao/jiansuo',
    name: 'fapiaojiansuo',
    meta: {
      title: '发票检索'
    },
    component: require('@/pages/fapiao/jiansuo').default
  }
  
]

const router = new VueRouter({
  routes
})


router.beforeEach(async (to, from, next) => {
  let navs = store.state.navs;
  if (navs.length == 0 && to.meta.layout != 'simple') {
    const r = await axios.get("/user/getNavs");
    if (r.status == 1) {
      // 保存所有导航数组到store
      navs = r.navs;
      store.commit("setNavs", navs);

      // ----------------- 生成一级菜单 -----------------
      const menus = []; //最终生成的一二级菜单
      navs.forEach((nav) => {
        if (nav.class == 1) menus.push(nav);
      });

      // ----------------- 生成二级菜单 -----------------
      for (let menu of menus) {
        menu.children = [];
        navs.forEach((nav) => {
          if (nav.father == menu.id && nav.show == 1) menu.children.push(nav);
        });
        if (menu.children.length == 0) delete menu.children;
      }
      // 保存菜单到store
      store.commit("setMenus", menus);
    }
  }

  // console.log(navs);
  let selectedKeys = []; //当前选中菜单
  let openKeys = []; //当前展开菜单
  const breadcrumbs = []; //面包屑

  const now_path = to.path;
  let nowNav; //当前正在运算的nav

  for (let nav of navs) {
    if (nav.path == now_path || nav.route == now_path) {
      nowNav = nav;
      break
    }
  }
  // console.log(nowNav, 88822);
  if (!nowNav) return next()

  const getFather = (id) => {
    for (let nav of navs) {
      if (nav.id == id) return nav
    }
  }

  // 根据father层层查找
  const hello = (nowNav) => {
    if (!nowNav) return
    breadcrumbs.unshift(nowNav.title);
    if (nowNav.class == 2 && nowNav.father) selectedKeys.unshift(nowNav.id);
    if (nowNav.class == 1 && !nowNav.father) openKeys.unshift(nowNav.id);

    if (nowNav.father !== 0) {
      const father = getFather(nowNav.father);
      hello(father)
    }
  }

  if (nowNav.class == 1) {
    selectedKeys.unshift(nowNav.id);
    breadcrumbs.unshift(nowNav.title);
  }
  else {
    hello(nowNav)
  }

  store.commit('setActiveMenu', {
    selectedKeys,
    openKeys,
    breadcrumbs
  })

  next()
})

// 避免跳转到当前网址的报错
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}


export default router
