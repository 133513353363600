<template>
  <div class="component-wrap">
    <a-form :form="form" class="form sec" :label-col="{ span: 10 }" :wrapper-col="{ span: 10 }">
      <a-form-item label="行驶证">
        <UploadPic :folder="'xingshizheng'" :type="'xingshizheng'" :oldUrl="xszUrl" @callbackPic="xszCallback" />
      </a-form-item>

      <a-form-item label="车牌号">
        <a-input style="width: 60%" placeholder="请输入车牌号" v-decorator="['chepai', {
          rules: [
            {
              required: true,
              message: '请输入车牌号'
            }]
        }]"></a-input>
      </a-form-item>

      <a-form-item label="车架号">
        <a-input style="width: 60%" placeholder="请输入车架号" v-decorator="['chejia', {
          rules: [
            {
              required: true,
              message: '请输入车架号'
            }]
        }]"></a-input>
      </a-form-item>
    </a-form>
    <div class="buttons">
      <a-button type="primary" @click="sub">提交</a-button>
    </div>
  </div>
</template>

<style lang='scss' scoped>
hr {
  border-top: none;
  margin: 40px;
}

h4 {
  margin-left: 20vw;
}
</style>

<script>
import UploadPic from '../../components/uploadPic.vue';
export default {
  name: 'carUpdate',
  components: { UploadPic },
  data() {
    return {
      id: null,
      form: null,
      xszUrl: ''
    };
  },
  async created() {
    this.id = this.$route.query.id;
    const r = await this.$axios.get("/car/getInfo?id=" + this.id);
    if (r.status != 1) return
    this.xszUrl = r.info.image
    this.form = this.$form.createForm(this, {
      name: 'carForm',
      mapPropsToFields: () => {
        return {
          chepai: this.$form.createFormField({
            value: r.info.chepai
          }),
          chejia: this.$form.createFormField({
            value: r.info.chejia
          })
        };
      },
    });
  },
  mounted() { },
  methods: {
    xszCallback(d) {
      this.xszUrl = d.file_path
    },
    async sub() {
      const that = this
      if (!this.xszUrl) {
        this.$message.error('请上传行驶证！')
        return
      }
      this.form.validateFields(async (err, value) => {
        if (!err) {
          const form = JSON.parse(JSON.stringify(value))
          const data = {
            id: that.id,
            chepai: form.chepai,
            chejia: form.chejia,
            image: that.xszUrl
          }
          const r = await this.$axios.post("/car/update", data);
          if (r.status == 1) {
            setTimeout(() => {
              that.$router.push("/car/detail?id=" + that.id)
            }, 2000);
          }
        }
      });
    },
  },
};
</script>