<template>
  <div class="flex-page">

    <a-table class="main" size="small" :rowKey="(record) => record.id" bordered :columns="columns" :data-source="list"
      :pagination="false">
      <template slot="action" slot-scope="text, record">
        <a-button size="small" type="primary" @click='editFn(record)'>编辑</a-button>
      </template>
    </a-table>

    <a-modal title="添加单位" v-model="edit.show" @ok="doEdit" @cancel="cancelAdd">
      <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 18 }">
        <a-form-item label="权限名称">
          <a-input v-model="edit.group_name"></a-input>
        </a-form-item>
        <a-form-item label="权限说明">
          <a-textarea style="min-height: 200px;" v-model="edit.desc" />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<style lang='scss' scoped>
</style>

<script>
  const columns = [
    {
      title: "序号",
      dataIndex: "id",
    },
    {
      title: "权限",
      dataIndex: "group_name",
    },
    {
      title: "说明",
      dataIndex: "desc",
    },
    {
      title: "操作",
      scopedSlots: {
        customRender: "action",
      },
    },
  ];

  export default {
    data() {
      return {
        columns,
        list: [],
        edit: {
          show: false,
          id: null,
          group_name: "",
          desc: '',
        },
      };
    },
    created() {
      this.getList();
    },
    methods: {
      cancelAdd() {

      },
      editFn(item) {
        this.edit = {
          show: true,
          id: item.id,
          group_name: item.group_name,
          desc: item.desc
        }
      },
      async getList() {
        const r = await this.$axios.get("/user/getGroupList");

        this.list = r.list;
      },
      async doEdit(id, status) {
        const r = await this.$axios.post("/user/updateStatus", {
          id: id,
          status: status,
        });
        if (r.status == 1) {
          this.getList();
        }
      }
    },
  };
</script>