<template>
    <div class="page">
        <a-form class="ctrl" layout="inline">

            <a-form-item label="名称(全称)">
                <a-input style="width: 200px" placeholder="请输入名称(全称)" v-model="options.tou_name"></a-input>
            </a-form-item>
            <a-form-item label="社会信用代码">
                <a-input style="width: 200px" placeholder="请输入社会信用代码" v-model="options.tou_sn"></a-input>
            </a-form-item>

            <a-form-item>
                <a-button type="primary" @click="search">搜索</a-button>
            </a-form-item>
        </a-form>

        <div class="statistics">共{{ page.amount }}个</div>
        <a-table class="sec" size="small" :rowKey="(record) => record.id" bordered :columns="columns"
            :data-source="list" :pagination="false">

            <template slot="action" slot-scope="text, record">

                <a-button style="margin: 5px" size="small" type=""
                    @click="goto('/customer/detail?id=' + record.id)">详情</a-button>
                <a-button size="small" v-if="![2,4].includes($store.state.user.group_id)" type="primary"
                    @click="goto('/customer/update?id=' + record.id)">编辑</a-button>

                <a-popconfirm title="确定要删除吗" v-if="record.status == 1 && ![2,4].includes($store.state.user.group_id)"
                    @confirm="updateStatus(record.id, 0)" okText="确定" cancelText="取消">
                    <a-button style="margin: 5px" size="small" type="danger">删除</a-button>
                </a-popconfirm>

                <a-button style="margin: 5px" size="small" type="" @click="gotoDingdan(record.tou_name)">查询订单</a-button>
            </template>
        </a-table>

        <a-pagination class="pagination" showQuickJumper v-model="page.now_page" :defaultPageSize="50"
            :total="page.amount" @change="getList" />
    </div>
</template>

<style lang='scss' scoped></style>

<script>

const columns = [
    {
        title: "#",
        dataIndex: "id",
    },
    {
        title: "名称(全称)",
        dataIndex: "tou_name",
    },
    {
        title: "社会信用代码 ",
        dataIndex: "tou_sn",
    },
    {
        title: "所在城市",
        dataIndex: "tou_city",
    },
    {
        title: "联系地址",
        dataIndex: "tou_addr",
    },

    {
        title: "添加时间",
        dataIndex: "create_time",
    },
    {
        title: "操作",
        scopedSlots: {
            customRender: "action",
        },
    },
];

export default {
    name: 'customerList',
    data() {
        return {
            columns,
            list: [],
            options: {
                tou_name: '',
                tou_sn: ''
            },
            page: {
                now_page: 1,
                page_size: 50,
                amount: 0,
            },
        };
    },
    created() {
        this.getList();
    },
    methods: {
        search() {
            this.page.now_page = 1;
            this.getList();
        },

        async getList() {
            const options = { ...this.options };
            options.nowPage = this.page.now_page;
            const r = await this.$axios.post("/customer/getList", options);
            this.list = r.list;
            this.page.amount = r.amount;
        },

        goto(url) {
            // 打开新窗口
            // let routeData = this.$router.resolve({ path: url });
            // window.open(routeData.href, '_blank');
            // this.$router.push({
            //     path:'/xieyi?idtype='+ num
            // });

            this.$router.push(url);
        },
        gotoDingdan(tou_name) {
            if (this.$store.state.user.group_id == 2) {
                this.$router.push('/order/myList?tou_name=' + tou_name)
            } else {
                this.$router.push('/order/list?tou_name=' + tou_name)
            }
        },

        async updateStatus(id, status) {
            const r = await this.$axios.post("/customer/updateStatus", {
                id,
                status,
            });
            if (r.status == 1) this.getList();
        }
    },
};
</script>