var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"component-wrap"},[_c('a-form',{staticClass:"form sec",attrs:{"form":_vm.form,"label-col":{ span: 10 },"wrapper-col":{ span: 10 }}},[([1, '1', 3, '3'].includes(_vm.$store.state.user.group_id))?_c('a-form-item',{attrs:{"label":"权限"}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:(['group_id', { rules: [{ required: true }] }]),expression:"['group_id', { rules: [{ required: true }] }]"}]},[_c('a-radio',{attrs:{"value":2}},[_vm._v(" 代理 ")]),_c('a-radio',{attrs:{"value":3}},[_vm._v(" 内勤 ")])],1)],1):_vm._e(),_c('a-form-item',{attrs:{"label":"姓名"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['name', { rules: [{ required: true, message: '请输入姓名' }] }]),expression:"['name', { rules: [{ required: true, message: '请输入姓名' }] }]"}],staticStyle:{"width":"60%"},attrs:{"placeholder":"请输入姓名"}})],1),_c('a-form-item',{attrs:{"label":"手机号"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['tel', {
    rules: [
      {
        required: true,
        message: ''
      },
      {
        validator: _vm.validateTel,
      }
    ]
  }]),expression:"['tel', {\n    rules: [\n      {\n        required: true,\n        message: ''\n      },\n      {\n        validator: validateTel,\n      }\n    ]\n  }]"}],staticStyle:{"width":"60%"},attrs:{"placeholder":"请输入手机号"}})],1),_c('a-form-item',{attrs:{"label":"登录密码"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['password', { rules: [{ required: true, message: '请输入登录密码' }] }]),expression:"['password', { rules: [{ required: true, message: '请输入登录密码' }] }]"}],staticStyle:{"width":"60%"},attrs:{"type":"password","placeholder":"请输入登录密码"}})],1),_c('a-form-item',{attrs:{"label":"确认密码"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['confirmPassword', {
    rules: [
      { required: true, message: '' },
      { validator: _vm.validatorPassword }
    ]
  }]),expression:"['confirmPassword', {\n    rules: [\n      { required: true, message: '' },\n      { validator: validatorPassword }\n    ]\n  }]"}],staticStyle:{"width":"60%"},attrs:{"type":"password","placeholder":"请确认密码"}})],1),_c('a-form-item',{attrs:{"label":"身份证号"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['IDcard', {
    rules: [
      {
        required: true,
        message: ''
      },
      {
        validator: _vm.validateIDcard,
      }]
  }]),expression:"['IDcard', {\n    rules: [\n      {\n        required: true,\n        message: ''\n      },\n      {\n        validator: validateIDcard,\n      }]\n  }]"}],staticStyle:{"width":"60%"},attrs:{"placeholder":"请输入身份证号"}})],1),_c('a-form-item',{attrs:{"label":"家庭地址"}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:(['addr']),expression:"['addr']"}],staticStyle:{"width":"60%"},attrs:{"placeholder":"请输入家庭地址"}})],1),_c('a-form-item',{attrs:{"label":"备注信息"}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:(['desc']),expression:"['desc']"}],staticStyle:{"width":"60%"},attrs:{"placeholder":"请输入备注信息","auto-size":{ minRows: 3, maxRows: 6 }}})],1)],1),_c('div',{staticClass:"buttons"},[_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.sub}},[_vm._v("提交")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }