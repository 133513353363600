<template>
  <div id="app" :class="{ simple: $route.meta.layout == 'simple' }">
    <template v-if="$route.meta.layout == 'simple'">
      <a-config-provider :locale="locale">
        <router-view />
      </a-config-provider>
    </template>

    <template v-else>
      <Aside />
      <div class="wrap">
        <Header />
        <PageTabs />
        <main class="container">
          <a-config-provider :locale="locale">
            <keep-alive :include="$store.state.keepRoutes">
              <router-view :key="$route.fullPath"/>
            </keep-alive>
          </a-config-provider>
        </main>
      </div>
    </template>
  </div>
</template>

<script>
import Aside from "./components/layout/aside";
import Header from "./components/layout/header";
import PageTabs from "./components/layout/pageTabs";
import zhCN from "ant-design-vue/lib/locale-provider/zh_CN";

export default {
  components: { Aside, Header, PageTabs },
  data() {
    return {
      locale: zhCN,
    };
  }
};
</script>