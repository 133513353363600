<template>
    <div class="page">
        <a-form class="ctrl" layout="inline">
         
            <a-form-item label="客户名称">
                <a-input style="width: 200px" placeholder="请输入姓名" v-model="options.name"></a-input>
            </a-form-item>
            <!-- <a-form-item label="车牌号">
                <a-input style="width: 200px" placeholder="请输入姓名" v-model="options.chepai"></a-input>
            </a-form-item> -->
            <!-- <a-form-item label="开票日期">
                <a-date-picker @change="getList" v-model="options.kaipiao_start" /> -
                <a-date-picker @change="getList" v-model="options.kaipiao_end" />
            </a-form-item> -->

            <a-form-item>
                <a-button type="primary" @click="search">搜索</a-button>
            </a-form-item>
        </a-form>

        <div class="statistics">共{{ page.amount }}个, 共{{ page.fee || 0 }}元</div>
        <a-table class="sec" size="small" :rowKey="(record) => record.id" bordered :columns="columns"
            :data-source="list" :pagination="false">


            <template slot="action" slot-scope="text, record">
                <a-tooltip>
                    <template slot="title">
                        查看发票
                    </template>
                    <a-button @click="goto(record.path)" style="margin-right: 5px" size="small" icon="eye"></a-button>
                </a-tooltip>
            </template>
        </a-table>
        <a-pagination class="pagination" showQuickJumper v-model="page.now_page" :defaultPageSize="50"
            :total="page.amount" @change="getList" />

        <a-modal :title="modalTitle" :visible="singlePdfData.show" @cancel="cancelSinglePdfData" @ok="subSinglePdfData">
            <uploadSinglePdf @uploadSinglePdfCallBack="uploadSinglePdfCallBack" :folder="'fapiao'" />

            <a-form class="ctrl" :label-col="{ span: 4 }" :wrapper-col="{ span: 18 }">
                <a-form-item label="发票">
                    <div v-if="singlePdfData.path" >
                        已上传<a-button style="margin-left: 10px;" @click="xiazaiHetong(singlePdfData.path)">查看发票</a-button>
                    </div>
                    <div v-else >
                        未上传
                    </div>
                </a-form-item>
                <a-form-item label="客户名称">
                    <a-input v-model="singlePdfData.kehumingcheng"></a-input>
                </a-form-item>
                <a-form-item label="开票日期">
                    <a-date-picker v-model="singlePdfData.kaipiaoriqi" />
                </a-form-item>
                <a-form-item label="金额">
                    <a-input type="number" v-model="singlePdfData.jine"></a-input>
                </a-form-item>
            </a-form>
        </a-modal>

    </div>
</template>

<style lang='scss' scoped></style>

<script>
import UploadSinglePdf from '../../components/uploadSinglePdf.vue';
import moment from 'moment'
const columns = [
    {
        title: "#",
        dataIndex: "id",
    },
    {
        title: "客户名称",
        dataIndex: "kehumingcheng",
    },
    {
        title: "开票日期",
        dataIndex: "kaipiaoriqi"
    },
    {
        title: "金额",
        dataIndex: "jine",
    },
    {
        title: "添加时间",
        dataIndex: "created_at",
    },
    {
        title: "操作",
        scopedSlots: {
            customRender: "action",
        },
    },
];

export default {
    name: 'fapiaojiansuo',
    components: { UploadSinglePdf },
    data() {
        return {
            columns,
            list: [],
            options: {
                name: '',
                kaipiao_start: '',
                kaipiao_end: '',
                // chepai: ''
            },
            page: {
                now_page: 1,
                page_size: 50,
                amount: 0,
                fee: 0
            },
            singlePdfData: {
                show: false
            },
            modalTitle: '编辑发票'
        };
    },
    activated() {
        this.getList();
    },
    methods: {
        search() {
            this.page.now_page = 1;
            this.getList();
        },

        async getList() {
            // if(!this.options.name){
            //     this.$message.error('请输入客户名称检索')
            //     return
            // }
            const options = { ...this.options };
            options.nowPage = this.page.now_page;
            const r = await this.$axios.post("/fapiao/getJiansuoList", options);
            if (r.status != 1) return;
            this.list = r.list;
            this.page.amount = r.amount.amount;
            this.page.fee = r.amount.money;
        },

        goto(url) {
            // 打开新窗口
            // let routeData = this.$router.resolve({ path: url });
            // window.open(routeData.href, '_blank');
            // this.$router.push({
            //     path:'/xieyi?idtype='+ num
            // });
            if(!url.includes('project/')) {
                window.open(this.ossUrl + '/project' + url)
            }else{
                window.open(this.ossUrl + url)
            }
        },

        gotoDingdan(id) {
            this.$router.push('/order/list?daili_id=' + id)
        },

        async updateStatus(id, status) {
            const r = await this.$axios.post("/fapiao/updateStatus", {
                id,
                status,
            });

            if (r.status == 1) this.getList();
        },

        showEdit(record) {
            this.singlePdfData = JSON.parse(JSON.stringify(record))
            this.singlePdfData.kaipiaoriqi = moment(this.singlePdfData.kaipiaoriqi, 'YYYY年MM月DD日')
            this.singlePdfData.show = true
            this.modalTitle = '编辑发票'
        },

        xiazaiHetong(url) {
            if(!url.includes('project/')) {
                window.open(this.ossUrl + '/project' + url)
            }else{
                window.open(this.ossUrl + url)
            }
        },
        cancelSinglePdfData() {
            this.singlePdfData = {
                show: false
            }
        },
        async subSinglePdfData() {
            if(!this.singlePdfData.path) {
                this.$message.error('请填上传发票')
                return
            }
            if(!this.singlePdfData.kehumingcheng) {
                this.$message.error('请填写客户名称')
                return
            }
            if(!this.singlePdfData.kaipiaoriqi) {
                this.$message.error('请选择开票日期')
                return
            }
            if(!this.singlePdfData.jine || this.singlePdfData.jine <= 0) {
                this.$message.error('请填写正确的金额')
                return
            }
            const apiUrl = this.singlePdfData.id ? '/fapiao/update' : '/fapiao/create'
            const r = await this.$axios.post(apiUrl, this.singlePdfData)
            if(r.status == 1) {
                this.getList()
                this.cancelSinglePdfData()
            }
        },
        uploadSinglePdfCallBack(d) {
            if(!d.file_path){
                this.$message.error('请上传发票')
                return
            }
            this.singlePdfData.path = d.file_path
        },
        showAddFapiao() {
            this. singlePdfData = {
                kehumingcheng: '',
                kaipiaoriqi: null,
                path: '',
                show: true
            }
            this.modalTitle = '添加发票'
        }
    },
};
</script>