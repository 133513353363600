<template>
  <div class="component-wrap">
    <a-form :form="form"  class="form sec" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
      <h4>承保单位</h4>
      <a-row>
        <a-col :span="12">
          <a-form-item label="承保单位">
            <a-radio-group v-model="chengbaodanwei" >
              <a-radio :value="'淄博'">
                淄博
              </a-radio>
              <a-radio :value="'青岛'">
                青岛
              </a-radio>
            </a-radio-group>
          </a-form-item>
        </a-col>
      </a-row>
      
      <hr />
      <h4>投保人基本信息</h4>
      <a-row>
        <a-col :span="12">
          <a-form-item label="营业执照">
            <a-col :span="12">
              <UploadPicDragger :folder="'yingyezhizhao'" :type="'yingyezhizhao'" @callbackPic="yyzzCallback" />
            </a-col>
            <a-col :span="12">
              <img v-if="yyzzUrl" style="width: 100px; margin-left: 10px;" :src="ossUrl + yyzzUrl" alt="">
            </a-col>
          </a-form-item>
        </a-col>
      </a-row>

      <a-row>
        <a-col :span="12">
          <a-form-item label="名称(全称)">
            <!-- <a-input-search
              placeholder="请填写名称(全称)"
              v-decorator="['tou_name', { rules: [{ required: true, message: '请填写名称(全称)' }] }]"
              enter-button 
              @search="onSearchName" 
            /> -->
            <a-input placeholder="请填写名称(全称)"
              v-decorator="['tou_name', { rules: [{ required: true, message: '请填写名称(全称)' }] }]" />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="社会信用代码">
            <a-input placeholder="请填写社会信用代码"
              v-decorator="['tou_sn', { rules: [{ required: true, message: '请填写社会信用代码' }] }]" />
          </a-form-item>
        </a-col>
      </a-row>

      <a-row>
        <a-col :span="12">
          <a-form-item label="所在城市">
            <a-input placeholder="请填写所在城市"
              v-decorator="['tou_city', { rules: [{ required: true, message: '请填写所在城市' }] }]" />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="联系地址">
            <a-textarea placeholder="请填写联系地址"
              v-decorator="['tou_addr', { rules: [{ required: true, message: '请填写联系地址' }] }]" />
          </a-form-item>
        </a-col>
      </a-row>

      <hr />
      <h4>投保信息</h4>

      <a-row>
        <a-col :span="12">
          <a-form-item label="行驶证">
            <a-col :span="12">
              <UploadPicDragger :folder="'xingshizheng'" :type="'xingshizheng'" @callbackPic="xszCallback" />
            </a-col>
            <a-col :span="12">
              <img v-if="xszUrl" style="width: 100px; margin-left: 10px;" :src="ossUrl + xszUrl" alt="">
            </a-col>
          </a-form-item>
        </a-col>
      </a-row>

      <a-row>
        <a-col :span="12">
          <a-form-item label="车牌号">
            <a-input type="text" placeholder="请填写车牌号"
              v-decorator="['chepai', { rules: [{ required: true, message: '请填写车牌号' }] }]" />
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="车架号">
            <a-input type="text" placeholder="请填写车架号"
              v-decorator="['chejia', { rules: [{ required: true, message: '请填写车架号' }] }]" />
          </a-form-item>
        </a-col>
      </a-row>

      <a-row>
        <a-col :span="12">
          <a-form-item label="估计雇员人数">
            <a-radio-group name="guyaunRadioGroup" v-model="guyuanrenshu" @change="jisuanPeichang">
              <a-radio :value="1">
                1人
              </a-radio>
              <a-radio :value="2">
                2人
              </a-radio>
            </a-radio-group>
          </a-form-item>
        </a-col>

        <a-col :span="12">
          <a-form-item label="保险金额">
            <a-radio-group name="radioGroup" v-model="peichangfangan" @change="jisuanPeichang">
              <a-radio v-for="(item, key) in setting.fangan" :key="key" :value="item">
                {{ item }}万
              </a-radio>
            </a-radio-group>
          </a-form-item>
        </a-col>
      </a-row>

      <a-row>
        <a-col :span="12">
          <a-form-item label="保险费率">
            {{ setting.feilv }}‰
          </a-form-item>
        </a-col>
        <a-col :span="12">
          <a-form-item label="保险费">
            <div>
              大写：人民币 {{ daxieBaoxianfei }}
            </div>
            <div>
              小写：￥{{ baoxianfei }}
            </div>
          </a-form-item>
        </a-col>
      </a-row>

      <a-row>
        <a-col :span="12">
          <a-form-item label="每人累计赔偿限额">
            <a-input type="number" placeholder="请填写每人累计赔偿限额" disabled
              v-decorator="['leijixiane', { rules: [{ required: true, message: '请填写每人累计赔偿限额' }] }]" suffix="万元" />
          </a-form-item>
        </a-col>

        <a-col :span="12">
          <a-form-item label="死亡/伤残赔偿责任">
            <a-input type="number" placeholder="请填写死亡/伤残赔偿责任" disabled
              v-decorator="['siwangshangcan', { rules: [{ required: true, message: '请填写每人累计赔偿限额' }] }]" suffix="万元" />
          </a-form-item>
        </a-col>
      </a-row>

      <a-row>
        <a-col :span="12">
          <a-form-item label="误工/医疗赔偿责任">
            <a-input type="number" placeholder="请填写误工/医疗赔偿责任" disabled
              v-decorator="['wugongyiliao', { rules: [{ required: true, message: '请填写误工/医疗赔偿责任' }] }]" suffix="万元" />
          </a-form-item>
        </a-col>

        <a-col :span="12">
          <!-- <a-range-picker v-decorator="['range', { rules: [{ required: true, message: '请选择开始和结束时间' }] }]" /> -->
          <a-form-item label="保险期间" style="margin-bottom:0; position: relative;">
            <div style="position: absolute; left: -79px; top: -8px; color: red;">*</div>
            <a-form-item :style="{ display: 'inline-block' }">
              <a-date-picker :disabled-date="disabledDate" v-decorator="['start_at', { rules: [{ required: true, message: '请选择开始时间' }] }]"
                @change="changeStartTime" />
            </a-form-item>
            <span :style="{ display: 'inline-block', width: '24px', textAlign: 'center' }">
              -
            </span>
            <a-form-item :style="{ display: 'inline-block' }">
              <a-date-picker v-decorator="['end_at', { rules: [{ required: true, message: '请选择结束时间' }] }]" />
            </a-form-item>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>

    <!-- 提交按钮 -->
    <div class="buttons">
      <a-button type="primary" @click="save">保存</a-button>
    </div>
  </div>
</template>

<style lang='scss' scoped>
hr {
  border-top: none;
  margin-bottom: 30px;
}

h4 {
  margin-left: 25px;
  color: #1785ff;
}
</style>

<script>

import moment from 'moment'
import UploadPicDragger from '../../components/uploadPicDragger.vue';
export default {
  name: 'orderCreate',
  components: { UploadPicDragger },
  data() {
    return {
      nowFormKey: Date.now(),
      form: this.$form.createForm(this, { name: 'order' }),
      yyzzUrl: '',
      xszUrl: '',
      setting: {
        feilv: null,
        fangan: null
      },
      guyuanrenshu: 1,
      chengbaodanwei: '淄博',
      peichangfangan: null,
      baoxianfei: 0,
      daxieBaoxianfei: '',
      testDizhi: ''
    };
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, {
      name: 'order',
      mapPropsToFields: () => {
        return {
          tou_name: this.$form.createFormField({
            value: ''
          }),
          tou_sn: this.$form.createFormField({
            value: ''
          }),
          tou_city: this.$form.createFormField({
            value: ''
          }),
          tou_addr: this.$form.createFormField({
            value: ''
          }),
          leijixiane: this.$form.createFormField({
            value: null
          }),
          siwangshangcan: this.$form.createFormField({
            value: null
          }),
          wugongyiliao: this.$form.createFormField({
            value: null
          }),
          chepai: this.$form.createFormField({
            value: ''
          }),
          chejia: this.$form.createFormField({
            value: ''
          }),
          start_at: this.$form.createFormField({
            value: null
          }),
          end_at: this.$form.createFormField({
            value: null
          })

          // range: this.$form.createFormField({
          //   value: range
          // })

        };
      },
    });
  },
  async created() {
    await this.getSetting()
  },
  mounted() {
    this.form.setFieldsValue({
      start_at: moment().add(1, 'days'),
      end_at: moment().add(1, 'years')
    });
  },
  methods: {
    save() {
      const that = this
      if (!that.yyzzUrl || !that.xszUrl) {
        this.$message.error('请上传营业执照和行驶证!')
        return
      }
      this.form.validateFields(async (err, value) => {
        if (!err) {
          that.$message.loading('正在保存', 0)
          const form = JSON.parse(JSON.stringify(value))
          form.chengbaodanwei = that.chengbaodanwei
          form.yyzz_url = that.yyzzUrl
          form.xsz_url = that.xszUrl
          form.feilv = that.setting.feilv
          form.guyuanrenshu = that.guyuanrenshu
          form.peichangfangan = that.peichangfangan
          form.baoxianfei = that.baoxianfei
          form.daxieBaoxianfei = that.daxieBaoxianfei
          const r = await this.$axios.post("/order/create", form);
          that.$message.destroy()
          if (r.status == 1) {
            this.$message.success(r.msg)
            // const toUrl = this.$store.state.user.group_id == 2 ? '/order/myList' : '/order/list'
            const toUrl = '/order/myList'
            setTimeout(() => {
              that.$router.push(toUrl);
            }, 1000);
          }else{
            this.$message.error(r.msg)
          }
        }
      });
    },
    async yyzzCallback(d) {
      this.yyzzUrl = d.file_path
      // 识别营业执照
      this.$message.loading('正在识别', 0)
      const r = await this.$axios.post("/alicloudOpenApi/ocrYyzz", { url: this.yyzzUrl });
      if (r.status == 1) {
        if (r.data.address) {
          this.$message.destroy()
          this.$message.success('识别成功')
          const reg = /.+?(省|市|自治区|自治州|县|区)/g
          const cityNameArr = r.data.address.match(reg)
          const cityName = cityNameArr && cityNameArr.length > 0 ? cityNameArr.join('') : ''
          this.form.setFieldsValue({
            tou_name: r.data.name,
            tou_sn: r.data.registerNumber,
            tou_city: cityName,
            tou_addr: r.data.address
          });
        } else {
          this.$message.destroy()
          this.$message.error('识别失败')
        }
      } else {
        this.$message.destroy()
        this.$message.error('识别失败')
      }
    },
    async xszCallback(d) {
      this.xszUrl = d.file_path
      // 识别营业执照
      this.$message.loading('正在识别', 0)
      const r = await this.$axios.post("/alicloudOpenApi/ocrXsz", { url: this.xszUrl });
      if (r.status == 1) {
        if (r.data.faceResult.plateNumber) {
          this.$message.destroy()
          this.$message.success('识别成功')
          this.form.setFieldsValue({
            chepai: r.data.faceResult.plateNumber,
            chejia: r.data.faceResult.vin
          });
        } else {
          this.$message.destroy()
          this.$message.error('识别失败')
        }
      } else {
        this.$message.destroy()
        this.$message.error('识别失败')
      }
    },
    // async onSearchName () {
    //   const tou_name = this.form.getFieldValue('tou_name')
    //   if(!tou_name) return
    //   const r = await this.$axios.post("/order/searchOrder", { tou_name: tou_name })

    //   if(r.status == 1) {
    //     const range = [moment(r.data[0].start_at), moment(r.data[0].end_at)]
    //     this.form = this.$form.createForm(this, {
    //     name: 'order',
    //     mapPropsToFields: () => {
    //       return {
    //         tou_name: this.$form.createFormField({
    //           value: r.data[0].tou_name
    //         }),
    //         tou_sn: this.$form.createFormField({
    //           value: r.data[0].tou_sn
    //         }),
    //         tou_city: this.$form.createFormField({
    //           value: r.data[0].tou_city
    //         }),
    //         tou_addr: this.$form.createFormField({
    //           value: r.data[0].tou_addr
    //         }),
    //         leijixiane: this.$form.createFormField({
    //           value: r.data[0].leijixiane
    //         }),
    //         siwangshangcan: this.$form.createFormField({
    //           value: r.data[0].siwangshangcan
    //         }),
    //         wugongyiliao: this.$form.createFormField({
    //           value: r.data[0].wugongyiliao
    //         }),
    //         chepai: this.$form.createFormField({
    //           value: r.data[0].chepai
    //         }),
    //         chejia: this.$form.createFormField({
    //           value: r.data[0].chejia
    //         }),
    //         range: this.$form.createFormField({
    //           value: range
    //         })
    //       };
    //     },
    //   });
    //   }
    // },
    changeStartTime(e) {
      this.form.setFieldsValue({
        end_at: moment(e).add(1, 'years').subtract(1, 'days')
      })
    },

    async getSetting() {
      const r = await this.$axios.get("/settings/getSettings");
      this.setting.fangan = r.data.fangan.split(',')
      this.setting.feilv = r.data.feilv
      this.peichangfangan = this.setting.fangan[0]
      // 计算赔偿责任限额等
      this.jisuanPeichang()
    },
    jisuanPeichang() {
      const that = this
      const siwangshangcan = Math.floor((that.peichangfangan * 10000 * (that.setting.feilv / 1000)) * 100) / 100
      const wugongyiliao = Math.floor((that.peichangfangan * 10000 * (that.setting.feilv / 1000) * 0.1) * 100) / 100
      this.baoxianfei = Math.floor((siwangshangcan + wugongyiliao) * 100 * that.guyuanrenshu) / 100
      this.daxieBaoxianfei = this.getDaxie() // 计算大写
      this.form.setFieldsValue({
        leijixiane: Math.floor((Number(that.peichangfangan) + Number(that.peichangfangan) * 0.1) * 100) / 100,
        siwangshangcan: that.peichangfangan,
        wugongyiliao: that.peichangfangan * 0.1
      });
    },

    getDaxie() {
      let money = this.baoxianfei
      if (money === '' || money == null || money === 'undefined') {
        return;
      }
      if (!/^(0|[1-9]\d*)(\.\d+)?$/.test(money)) {
        return "数据非法";
      }
      money = money * 1.0;
      if (money === 0) {
        return "零";
      }
      var unit = "仟佰拾亿仟佰拾万仟佰拾元角分", str = "";
      money += "00";
      var point = money.indexOf('.');
      if (point >= 0) {
        money = money.substring(0, point) + money.substr(point + 1, 2);
      }
      unit = unit.substr(unit.length - money.length);
      for (var i = 0; i < money.length; i++) {
        str += '零壹贰叁肆伍陆柒捌玖'.charAt(money.charAt(i)) + unit.charAt(i);
      }
      var result = str.replace(/零(仟|佰|拾|角)/g, "零").replace(/(零)+/g, "零").replace(/零(万|亿|元)/g, "$1").replace(/(亿)万/g, "$1$2").replace(/^元零?|零分/g, "").replace(/元$/g, "元整");
      return result;
    },

    ceshi() {
 console.log(this.$refs)

      // let addr = str1.split('区')[0] + '区'
      // if(addr.length == str1) {
      //   addr = str1.split('县')[0] + '县'
      //   if(addr.length == str1) {
      //     addr = str1.split('市')[0] + '市'
      //     if(addr.length == str1) {
      //       addr = str1.split('省')[0] + '省'
      //     }
      //   }
      // }
      // this.testDizhi = addr
    },
    disabledDate(current) {
      // Can not select days before today and today
      return current && current < moment().endOf('day');
    },
  },
};
</script>