<template>
    <a-modal title="文件预览" okText="确定" :visible="true" cancelText="取消" @ok="callback" @cancel="callback" width="1000px"
        :maskClosable="false">
        <!-- <canvas id="the-canvas"></canvas> -->
        <!-- <pdf
            class="pdf-preview"
            :src="pdfUrl"
          ></pdf> -->
        <!-- <embed
          :src="pdfUrl"
          type="application/pdf"
          width="100%"
          height="800px"
        /> -->
        <canvas ref="canvas"></canvas>

    </a-modal>
</template>

<style lang='scss' scoped></style>

<script>
export default {
    props: ['pdfUrl'],
    data() {
        return {
            location: {}
        };
    },
    created() {
    },
    mounted() {
        this.loadPdf();
    },
    methods: {
        callback() {
            this.$emit('callback')
        },
        async loadPdf() {
            try {
                // 获取pdf地址
                const url = this.pdfUrl
                // 加载pdf文件，并将每页转换成canvas图片存放到pages数组内
                const loadingTask = await pdfjsLib.getDocument(url)
                const totalPages = loadingTask.numPages
                for (let num = 1; num <= totalPages; num++) {
                    const page = await loadingTask.getPage(num)
                    const viewport = page.getViewport({scale: 1.0})
                    const canvas = document.createElement('canvas')
                    const context = canvas.getContext('2d')
                    canvas.height = viewport.height
                    canvas.width = viewport.width
                    await page.render({canvasContext: context, viewport: viewport})
                    const image = canvas.toDataURL('image/jpeg')
                    this.pages.push(image)
                }
            } catch (error) {
                console.error('Error loading PDF: ', error);
            }
        }
    },
};
</script>