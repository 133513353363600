<template>
    <div class="clearfix">
        <div class="preview-box">
            <div class="preview-img-box" v-for="(item, key) in fileList" :key='key' >
                <img  :src="API + item.response.file_path" alt="" @click="handlePreview(item)">
            </div>
        </div>
        <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
            <img alt="example" style="width: 100%" :src="previewImage" />
        </a-modal>
    </div>
</template>
<script>
function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}
export default {
    props: ['oldData', 'folder'],
    data() {
        return {
            route: this.API + "/file/uploadFile",
            headers: {
                token: "",
            },
            previewVisible: false,
            previewImage: '',
            fileList: [],
            data: {}
        };
    },
    watch: {
        // oldData() {
        //   this.initData()
        // }
    },
    created() {
        this.headers.token = this.$store.state.token
        this.data.folder = this.folder || "other";
        this.initData()
        console.log(this.fileList)
    },
    methods: {
        handleCancel() {
            this.previewVisible = false;
        },
        initData() {
            if (this.oldData && this.oldData.length > 0) {
                const oldList = []
                for (let key in this.oldData) {
                    oldList.push({
                        uid: key,
                        name: 'image.png',
                        status: 'done',
                        url: this.API + this.oldData[key],
                        isOld: true,
                        response: {
                            status: 1,
                            file_path: this.oldData[key]
                        }
                    })
                }
                this.fileList = oldList
            }
        },
        async handlePreview(file) {
            if (!file.url && !file.preview) {
                file.preview = await getBase64(file.originFileObj);
            }
            this.previewImage = file.url || file.preview;
            this.previewVisible = true;
        },

        handleChange(info) {
            let files = [];
            this.fileList = [];
            if (info.fileList && info.fileList.length) {
                let fileList = info.fileList
                // 2. read from response and show file link
                fileList = fileList.map((file) => {
                    if (file && file.response && !file.isOld) {
                        // Component will show file.url as link
                        file.url = file.response.url;
                    }
                    return file;
                });
                this.fileList = fileList;

                for (let item of fileList) {
                    if (item.status == "done" && item.response.status == 1) {
                        files.push(item.response.file_path)
                        // files = [
                        //     {
                        //         file_path: item.response.file_path,
                        //         file_name: item.response.fileObj.base,
                        //         fileObj: item.response.fileObj,
                        //     },
                        // ];
                    }
                }
            } else {
                this.fileList = [];
            }
            this.$emit("callbackPic", files);
        },

        beforeUpload(file) {
            const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
            if (!isJpgOrPng) {
                this.$message.error('只能上传jpg和png格式图片!');
            }
            const isLt2M = file.size / 1024 / 1024 < 5;
            if (!isLt2M) {
                this.$message.error('图片大小不能超过5MB!');
            }
            return isJpgOrPng && isLt2M;
        }
    },
};
</script>
<style scoped lang="scss">
/* you can make up upload button and sample style by using stylesheets */
.ant-upload-select-picture-card i {
    font-size: 32px;
    color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
    margin-top: 8px;
    color: #666;
}
</style>