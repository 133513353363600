<template>
    <a-modal title="修改密码" :visible="true" @cancel="cancel" @ok="sub">
        <a-form :form="form" :label-col="formItemLayout.labelCol" :wrapper-col="formItemLayout.wrapperCol">
            <a-form-item label="原密码">
                <a-input type="password" style="width: 60%" placeholder="请输入原密码"
                    v-decorator="['oldPassword', { rules: [{ required: true, message: '请输入原密码' }] }]"></a-input>
            </a-form-item>

            <a-form-item label="新密码">
                <a-input type="password" style="width: 60%" placeholder="请输入新密码"
                    v-decorator="['password', { rules: [{ required: true, message: '请输入新密码' }] }]"></a-input>
            </a-form-item>

            <a-form-item label="确认密码">
                <a-input type="password" style="width: 60%" placeholder="请确认密码" v-decorator="['confirmPassword', {
        rules: [
            { required: true, message: '' },
            { validator: validatorPassword }
        ]
    }]"></a-input>
            </a-form-item>
        </a-form>
    </a-modal>
</template>

<script>
export default {
    props: ['id'],
    data() {
        return {
            formItemLayout: {
                labelCol: {
                    span: 4,
                },
                wrapperCol: {
                    span: 20,
                },
            },
            form: null,
        };
    },
    created() {
        this.form = this.$form.createForm(this, {
            name: 'passwordForm',
            mapPropsToFields: () => {
                return {
                    oldPassword: this.$form.createFormField({
                        value: ''
                    }),
                    password: this.$form.createFormField({
                        value: ''
                    }),
                    confirmPassword: this.$form.createFormField({
                        value: null
                    })
                };
            },
        });
    },
    methods: {
        validatorPassword(rule, value, callback) {
            const form = this.form
            if (!value) {
                callback('请确认密码');
            } else if (value != form.getFieldValue('password')) {
                callback('两次输入的密码不一样');
            } else {
                callback()
            }
        },
        cancel() {
            this.$emit("upDataPSDResult", 1);
        },
        async sub() {
            const that = this
            this.form.validateFields(async (err, value) => {
                if (!err) {
                    const params = {
                        id: that.id,
                        password: value.password,
                        oldPsw: value.oldPassword
                    }
                    const r = await that.$axios.post('/user/updatePsw', params)
                    if (r.status == 1) {
                        this.$emit("upDataPSDResult", 1);
                    }
                }
            })

        },
    },
};
</script>