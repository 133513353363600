<template>
  <div class="page">
    <!-- 检索条件 类型 客户 电话 车牌号 创建时间段 服务时间段 状态 -->
    <a-form class="ctrl" layout="inline">
      <a-form-item label="员工">
        <a-select show-search placeholder="请选择员工" option-filter-prop="children" v-model="options.daili_who"
          style="width: 150px;" :filter-option="filterOption" @change="getList">
          <a-select-option :value="''">
            全部
          </a-select-option>
          <a-select-option v-for="(item, key) in dailiList" :key="key" :value="item.who">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-item>

      <a-form-item label="投保人">
        <a-input type="text" placeholder="请输入投保人" v-model="options.tou_name"></a-input>
      </a-form-item>

      <a-form-item label="车牌号">
        <a-input type="text" placeholder="请输入车牌号" v-model="options.chepai"></a-input>
      </a-form-item>

      <a-form-item label="保险期间">
        <a-date-picker @change="getList" v-model="options.start_at" /> -
        <a-date-picker @change="getList" v-model="options.end_at" />
      </a-form-item>

      <a-form-item label="投保时间">
        <a-date-picker @change="getList" v-model="options.create_start_at" /> -
        <a-date-picker @change="getList" v-model="options.create_end_at" />
      </a-form-item>

      <a-form-item label="状态">
        <a-select show-search placeholder="请选择状态" option-filter-prop="children" v-model="options.status"
          style="width: 100px;" @change="getList">
          <a-select-option :value="-10">
            全部
          </a-select-option>
          <a-select-option :value="1">
            未锁定
          </a-select-option>
          <a-select-option :value="2">
            已锁定
          </a-select-option>
          <a-select-option :value="3">
            已下载
          </a-select-option>

          <a-select-option :value="0">
            已删除
          </a-select-option>
        </a-select>
      </a-form-item>

      <a-form-item label="标记">
        <a-input type="text" placeholder="请输入标记" v-model="options.biaoji"></a-input>
      </a-form-item>

      <a-form-item label="保单号">
        <a-input type="text" placeholder="请输入保单号" v-model="options.baoxiandanhao"></a-input>
      </a-form-item>


      <a-form-item>
        <a-button type="primary" @click="getList">搜索</a-button>

        <a-button type="primary" style="margin-left: 5px;" @click="daochu">导出</a-button>
      </a-form-item>
    </a-form>

    <div class="statistics">
      <span>共{{ pages.amount }}条，保费共 {{ fee }} 元</span>

      <span v-if="selectedRowKeys.length">
        <a-button style="margin-left: 5px;" type="primary" @click="duogeBaodan">下载投保单</a-button>
        <a-button style="margin-left: 5px;" type="primary" @click="duogePdfBaodan">下载保单</a-button>
        <a-button style="margin-left: 5px;" type="primary" @click="duogeDabao">打包下载</a-button>
        <a-popconfirm title="确定要锁定吗？" ok-text="确认" cancel-text="取消" @confirm="updateDuogeStatus">
          <a-button style="margin-left: 5px;" type="danger">批量锁定</a-button>
        </a-popconfirm>
      </span>
    </div>

    <a-table class="sec" size="small" :rowKey="(record) => record.id" bordered :columns="columns" :data-source="list"
      :pagination="false" :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }">

      <template slot="uploadfiles" slot-scope="text, record">
        <span v-if="record.gaozhishuUrl && record.gaozhishuUrl.length">开票信息{{ record.gaozhishuUrl.length }}</span>
        <span style="margin-left: 10px;" v-if="record.tiaokuanUrl && record.tiaokuanUrl.length">条款{{
          record.tiaokuanUrl.length }}</span>
        <span style="margin-left: 10px;" v-if="record.toubaodanUrl && record.toubaodanUrl.length">投保单{{
          record.toubaodanUrl.length }}</span>
      </template>
      <template slot="baodan" slot-scope="text, record">
        <a-tooltip v-if="record.pdfUrl">
          <template slot="title">
            查看
          </template>
          <a-button @click="xiazaiHetong(record.pdfUrl)" style="margin-left: 5px" size="small" icon="eye"></a-button>
        </a-tooltip>
      </template>

      <template slot="status" slot-scope="text, record">
        <a-tag v-if="record.status == 1" color="orange">未锁定</a-tag>
        <a-tag v-if="record.status == 2" color="green">已锁定</a-tag>
        <a-tag v-if="record.status == 3" color="blue">已下载</a-tag>
      </template>
      <template slot="riqi" slot-scope="text, record">
        {{ record.start_at }} ~ {{ record.end_at }}
      </template>
      <template slot="action" slot-scope="text, record">
        <template v-if="record.status != 0">
          <a-tooltip>
            <template slot="title">
              查看
            </template>
            <a-button @click="jumpUrl('/order/info?id=' + record.id)" style="margin-left: 5px" size="small"
              icon="eye"></a-button>
          </a-tooltip>

          <a-popconfirm title="确定要锁定吗？" v-if="record.status < 2" ok-text="确认" cancel-text="取消"
            @confirm="updateStatus({ id: record.id, status: 2 })">
            <a-tooltip>
              <template slot="title">
                锁定
              </template>
              <a-button style="margin-left: 10px" size="small" type="danger" icon="lock"></a-button>
            </a-tooltip>
          </a-popconfirm>

          <a-popconfirm title="确定要取消锁定吗？" v-else ok-text="确认" cancel-text="取消"
            @confirm="updateStatus({ id: record.id, status: 1 })">
            <a-tooltip>
              <template slot="title">
                取消锁定
              </template>
              <a-button style="margin-left: 10px" size="small" icon="unlock"></a-button>
            </a-tooltip>
          </a-popconfirm>

          <a-tooltip>
            <template slot="title">
              {{ record.status < 2 ? '请先锁定再下载' : '打包下载' }} </template>
                <a-button @click="dabao(record.id)" :disabled="record.status < 2" style="margin-left: 10px" size="small"
                  type="primary" icon="cloud-download"></a-button>
          </a-tooltip>

          <a-tooltip>
            <template slot="title">
              上传
            </template>
            <a-button @click="showShangchuan(record)" :disabled="record.status != 1" style="margin-left: 10px"
              size="small" icon="cloud-upload"></a-button>
          </a-tooltip>

          <a-tooltip>
            <template slot="title">
              标记
            </template>
            <a-button style="margin-left: 10px" size="small" icon="highlight" @click="showBiaoji(record)"></a-button>
          </a-tooltip>

          <a-dropdown style="margin-left: 10px;">
            <a class="ant-dropdown-link" @click="e => e.preventDefault()">
              更多 <a-icon type="down" />
            </a>
            <a-menu slot="overlay">
              <a-menu-item key="1" @click="xiazaiHetong(record.baodan_url)">
                合同下载
              </a-menu-item>
              <a-menu-item key="2" @click="yulanHetong(record.baodan_url)">
                合同预览
              </a-menu-item>
              <a-menu-item v-if="record.status != 2" key="4" @click="jumpUrl('/order/edit?id=' + record.id)">
                编辑
              </a-menu-item>
              <a-menu-item v-if="record.status != 2" key="5" @click="showUploadPdf(record)">
                上传保单
              </a-menu-item>

              <a-menu-item v-if="record.status != 2" key="3">
                <a-popconfirm title="确定要删除吗？" ok-text="确认" cancel-text="取消" @confirm="del(record.id)">
                  <div style="width: 100%;">
                    删除
                  </div>
                </a-popconfirm>
              </a-menu-item>
            </a-menu>
          </a-dropdown>
        </template>

        <template v-else>
          <a-popconfirm title="确定要恢复吗？"  ok-text="确认" cancel-text="取消"
            @confirm="updateStatus({ id: record.id, status: 1 })">
            <a href="javascript:;;">恢复</a>
          </a-popconfirm>
        </template>

      </template>
    </a-table>
    <a-pagination class="pagination" showQuickJumper v-model="pages.now_page" :defaultPageSize="50"
      :total="pages.amount" @change="getList" />
    <WordYulan v-if="yulanData.show" :wordUrl="yulanData.url" @callback="closeYulan" />
    <PdfYulan v-if="yulanPdfData.show" :pdfUrl="yulanPdfData.url" @callback="closePdfYulan" />

    <UploadPicMultModel v-if="shangchuan.show" @updateCallBack="updateCallBack"
      :oldData="{ gaozhishuUrl: uploadRecord.gaozhishuUrl, tiaokuanUrl: uploadRecord.tiaokuanUrl, toubaodanUrl: uploadRecord.toubaodanUrl }" />

    <a-modal title="标记订单" :visible="biaojiData.show" @cancel="cancelBiaoji" @ok="subBioaji">
      <a-textarea :maxLength="200" v-model="biaojiData.data" placeholder="请填写标记"
        :auto-size="{ minRows: 3, maxRows: 5 }" />
    </a-modal>

    <a-modal title="上传保单" :visible="singlePdfData.show" @cancel="cancelSinglePdfData" @ok="subSinglePdfData">
      <uploadSinglePdf @uploadSinglePdfCallBack="uploadSinglePdfCallBack" :folder="'pdf'" />
      <div v-if="singlePdfData.url" style="display: flex; padding: 10px;">
        <div style="color: #1890ff; margin-right: 10px; display: flex; align-items: center; justify-self: center;">{{
          singlePdfData.name || '已上传' }}</div>
        <a-button @click="xiazaiHetong(singlePdfData.url)">查看保单</a-button>
      </div>
      <div v-else style="display: flex; padding: 10px;">
        未上传
      </div>
      <a-form class="ctrl" :label-col="{ span: 4 }" :wrapper-col="{ span: 18 }">
        <a-form-item label="保险单号">
          <a-input v-model="singlePdfData.baoxiandanhao"></a-input>
        </a-form-item>
        <a-form-item label="报案电话">
          <a-input v-model="singlePdfData.baoandianhua"></a-input>
        </a-form-item>
      </a-form>
    </a-modal>

  </div>
</template>

<style lang='scss' scoped></style>

<script>
import WordYulan from '../../components/wordYulan.vue';
import PdfYulan from '../../components/pdfYulan.vue';
import UploadPicMultModel from '../../components/uploadPicMultModel.vue';
import uploadSinglePdf from '../../components/uploadSinglePdf.vue';

const columns = [
  {
    title: "#",
    dataIndex: "id",
    // scopedSlots: {
    //   customRender: "xuhao",
    // }
  },
  {
    title: "承保单位",
    dataIndex: "chengbaodanwei",
  },
  {
    title: "投保人",
    dataIndex: "tou_name",
  },
  {
    title: "车牌",
    dataIndex: "chepai",
  },
  {
    title: "人数",
    dataIndex: "guyuanrenshu",
  },
  {
    title: "方案（万）",
    dataIndex: "peichangfangan",
  },
  {
    title: "保费",
    dataIndex: "baoxianfei",
  },

  {
    title: "投保时间",
    dataIndex: "create_time",
  },
  {
    title: "上传照片",
    scopedSlots: {
      customRender: "uploadfiles",
    }
  },
  {
    title: "保单",
    scopedSlots: {
      customRender: "baodan",
    }
  },
  {
    title: "标记备注",
    dataIndex: "biaojiRemark",
  },
  {
    title: "状态",
    scopedSlots: {
      customRender: "status",
    }
  },
  {
    title: "操作",
    scopedSlots: {
      customRender: "action",
    }
  },
];
export default {
  name: 'orderList',
  components: { WordYulan, PdfYulan, UploadPicMultModel, uploadSinglePdf },
  data() {
    return {
      options: {
        tou_name: '',
        start_at: null,
        end_at: null,
        daili_who: '',
        daili_id: null,
        chepai: '',
        create_time: null,
        create_start_at: null,
        create_end_at: null,
        biaoji: '',
        baoxiandanhao: '',
        status: -10
      },
      columns,
      list: [],
      fee: 0, //保费统计
      dailiList: [],
      pages: {
        now_page: 1,
        amount: 0,
        total_fee: 0,
      },
      name: '',
      id: '',
      selectedRowKeys: [],
      yulanData: {
        show: false,
        url: ''
      },
      yulanPdfData: {
        show: false,
        url: ''
      },
      shangchuan: {
        show: false
      },
      uploadRecord: {},
      biaojiData: {
        id: null,
        show: false,
        data: ''
      },
      singlePdfData: {
        id: null,
        url: null,
        name: '',
        show: false,
        baoxiandanhao: '',
        baoandianhua: ''
      },
      timer: null,
    };
  },
  computed: {
    rowSelection() {
      return {
        onChange: (selectedRowKeys, selectedRows) => {
          console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
        },
        getCheckboxProps: record => ({
          props: {
            disabled: record.name === 'Disabled User', // Column configuration not to be checked
            name: record.name,
          },
        }),
      };
    },
  },
  activated() {
    console.log('111111')
    this.init()
  },
  // async created() {

  // },
  methods: {
    async init() {
      await this.getAllDaili()
      if (this.$route.query.daili_id) {
        this.options.daili_id = this.$route.query.daili_id
        await this.getDailiInfo()
      }
      if (this.$route.query.chepai) {
        this.options.chepai = this.$route.query.chepai
      }

      if (this.$route.query.tou_name) {
        this.options.tou_name = this.$route.query.tou_name
      }
      await this.getList();
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    async getDailiInfo() {
      const r = await this.$axios.get('/user/getInfo?id=' + this.options.daili_id)
      if (r.status == 1) {
        this.options.daili_who = r.info.who
      }
    },
    goto(path, item) {
      this.$router.push(path);
    },
    async getAllDaili() {
      const r = await this.$axios.post('/user/getAllDaili', { group_id: [2, 3] })
      this.dailiList = r.list
    },
    async getList() {
      this.selectedRowKeys = []
      const options = { ...this.options };
      options.nowPage = this.pages.now_page;
      const r = await this.$axios.post("/order/getList", options);
      if (r.status == 1) {
        this.list = r.list;
        this.pages.amount = r.amount;
        this.fee = r.fee
      }
    },
    async del(id) {
      const r = await this.$axios.post("/order/delOrder", {
        id: id,
      });
      if (r.status == 1) {
        this.getList();
      }
    },
    xiazaiHetong(url) {
      if(!url.includes('project/')) {
        window.open(this.ossUrl + '/project' + url)
      }else{
        window.open(this.ossUrl + url)
      }
    },
    yulanHetong(url) {
      this.yulanData = {
        show: true,
        url: url.includes('project/') ? this.ossUrl + url : this.ossUrl + '/project' + url
      }
    },
    closeYulan() {
      this.yulanData = {
        show: false,
        url: ''
      }
    },
    jumpUrl(url) {
      this.$router.push(url)
      // let routeData = this.$router.resolve({ path: url });
      // window.open(routeData.href, '_blank');
    },
    yulanPdf(url) {
      this.yulanPdfData = {
        show: true,
        url: url.includes('project/') ? this.ossUrl + url : this.ossUrl + '/project' + url
      }
    },
    closePdfYulan() {
      this.yulanPdfData = {
        show: false,
        url: ''
      }
    },
    async dabao(id) {
      // const r = await this.$axios.post("/file/downloadZip", {
      //   order_id: id,
      // });
      // if (r.status == 1) {
      //   window.open(r.url)
      //   this.getList()
      // }
      const r = await this.$axios.post("/file/downloadZips", {
        ids: [id]
      })

      if(r.status == 1 && r.id) {
        this.getZipLog(r.id)
      }
      // console.log(r)
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },
    async duogeDabao() {
      if (this.selectedRowKeys.length == 0) {
        this.$message.error('没有选中的订单')
        return
      }

      // 检测是否已经都锁定
      for (let item of this.list) {
        if (this.selectedRowKeys.includes(item.id)) {
          if (item.status < 2) {
            this.$message.error('请先锁定')
            return
          }
        }
      }

      const r = await this.$axios.post("/file/downloadZips", {
        ids: this.selectedRowKeys
      })

      if(r.status == 1 && r.id) {
        this.getZipLog(r.id)
      }
    },
    async getZipLog(id) {
      // 定时五秒去查询，如果查询到了下载链接就下载
      const timer = setInterval(async ()=> {
        const r = await this.$axios.get("/file/getZipLog?id=" + id)
        console.log(r, 89888);
        if(r.url){
          window.open(r.url)
          clearInterval(timer)
        }
        // 后台失败了停止
        else if(r.status == -1){
          clearInterval(timer)
        }

        // 后台没有完成则继续
      }, 5000)
    },
    async duogeBaodan() {
      this.$message.loading('正在打包', 0)
      if (this.selectedRowKeys.length == 0) {
        setTimeout(() => {
          this.$message.destroy()
        }, 2000)
        this.$message.error('没有选中的订单')
        return
      }
      const r = await this.$axios.post("/file/downloadBaodanZips", {
        order_id: this.selectedRowKeys
      });
      if (r.status == 1) {
        window.open(r.url)
      }
      setTimeout(() => {
        this.$message.destroy()
      }, 2000)
    },
    async duogePdfBaodan() {
      this.$message.loading('正在打包', 0)
      if (this.selectedRowKeys.length == 0) {
        this.$message.error('没有选中的订单')
        setTimeout(() => {
          this.$message.destroy()
        }, 2000)
        return
      }
      const r = await this.$axios.post("/file/downloadPdfBaodanZips", {
        order_id: this.selectedRowKeys
      });
      if (r.status == 1) {
        window.open(r.url)
      }
      setTimeout(() => {
        this.$message.destroy()
      }, 2000)
    },
    async updateDuogeStatus() {
      if (this.selectedRowKeys.length == 0) {
        this.$message.error('没有选中的订单')
        return
      }
      const that = this
      const r = await this.$axios.post("/order/updateDuogeStatus", {
        order_ids: that.selectedRowKeys,
        status: 2
      });
      if (r.status == 1) {
        this.selectedRowKeys = []
        this.getList()
      }
    },
    async updateStatus(record) {
      const r = await this.$axios.post("/order/updateStatus", {
        order_id: record.id,
        status: record.status
      });
      if (r.status == 1) {
        this.getList()
      }
    },
    async showShangchuan(record) {
      if (record.status == 2) {
        this.$message.error('订单已锁定')
        return
      }
      this.uploadRecord = JSON.parse(JSON.stringify(record))
      this.uploadRecord.gaozhishuUrl = record.gaozhishuUrl
      this.uploadRecord.tiaokuanUrl = record.tiaokuanUrl
      this.uploadRecord.toubaodanUrl = record.toubaodanUrl
      this.shangchuan.show = true
      // this.$message.error('尚未完成')
    },
    async updateCallBack(e) {
      const that = this
      this.shangchuan.show = false
      if (e) {
        // 直接把e传到后台保存到数据库
        const r = await this.$axios.post("/order/updateTupian", {
          id: that.uploadRecord.id,
          data: e
        })
        this.getList()
      }
      this.uploadRecord = {}
    },
    showBiaoji(record) {
      this.biaojiData = {
        id: record.id,
        show: true,
        data: record.biaojiRemark
      }
    },
    cancelBiaoji() {
      this.biaojiData = {
        id: null,
        show: false,
        data: ''
      }
    },
    async subBioaji() {
      const that = this
      const r = await this.$axios.post("/order/doBiaoji", { id: that.biaojiData.id, data: that.biaojiData.data })
      if (r.status == 1) {
        this.getList()
        this.cancelBiaoji()
      }

    },
    async showUploadPdf(record) {
      this.singlePdfData = {
        id: record.id,
        name: '',
        url: record.pdfUrl,
        show: true,
        baoxiandanhao: record.baoxiandanhao,
        baoandianhua: record.baoandianhua
      }
    },
    async ceshi() {
      const that = this
      const r = await this.$axios.post("/order/ceshi", {})
    },
    uploadSinglePdfCallBack(d) {
      if (d.status == 1) {
        this.singlePdfData.url = d.file_path
        this.singlePdfData.name = d.fileObj.name
        this.$message.success('上传成功')
      }
    },
    cancelSinglePdfData() {
      this.singlePdfData = {
        id: null,
        url: null,
        name: '',
        show: false,
        baoxiandanhao: '',
        baoandianhua: ''
      }
    },
    async subSinglePdfData() {
      if (!this.singlePdfData.url) {
        this.$message.error('请上传pdf')
        return
      }
      if (!this.singlePdfData.baoxiandanhao) {
        this.$message.error('请填写保险单号')
        return
      }
      if (!this.singlePdfData.baoandianhua) {
        this.$message.error('请填写报案电话')
        return
      }
      const r = await this.$axios.post("/order/updateSinglePdfPath", {
        id: this.singlePdfData.id,
        url: this.singlePdfData.url,
        baoxiandanhao: this.singlePdfData.baoxiandanhao,
        baoandianhua: this.singlePdfData.baoandianhua
      })
      if (r.status == 1) {
        this.getList()
        this.cancelSinglePdfData()
      }
    },
    async daochu() {
      this.$message.loading('正在导出', 0)
      const r = await this.$axios.post("/order/daochu", this.options)
      location.href = this.API + r
      setTimeout(() => {
        this.$message.destroy()
      }, 2000)
    }
  },
};
</script>