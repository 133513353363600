<template>
    <div class="component-wrap">
        <a-form class="form sec" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
            <a-row>
                <a-col :span="24">
                    <div style="width: 100%;display: flex; align-items: center; justify-content: center;">
                        <div style="width: 500px;" :key="uploadKey">
                            <uploadPDFDragger :folder="'pdf'" :mult="true" @callbackPdf="callbackPdf" />
                        </div>
                    </div>
                </a-col>
            </a-row>

            <a-divider :dashed="true">识别失败</a-divider>
            <a-table class="sec" size="small" :rowKey="(record) => shibieResult.failList.indexOf(record)" bordered :columns="columns"
                :data-source="shibieResult.failList" :pagination="false">

                <template slot="name" slot-scope="text, record">
                    <div v-if="record.name" @click="yulanHetong(record.file_path)" style="color: #1890ff; cursor: pointer">
                        {{ record.name || '' }}
                    </div>
                </template>

                <template slot="quancheng" slot-scope="text, record">
                    {{ record.orderData.tou_name || '' }}
                </template>

                <template slot="addr" slot-scope="text, record">
                    {{ record.orderData.tou_addr || '' }}
                </template>

                <template slot="chepai" slot-scope="text, record">
                    {{ record.orderData.chepai || '' }}
                </template>

                <template slot="chejia" slot-scope="text, record">
                    {{ record.orderData.chejia || '' }}
                </template>

                <template slot="toubaoshijian" slot-scope="text, record">
                </template>

                <template slot="action" slot-scope="text, record">
                    <!-- <a-button @click="jumpUrl('/order/info?id=' + record.id)" style="margin-left: 5px" size="small"
                        type="">
                        查看
                    </a-button> -->
                </template>
            </a-table>

            <a-divider :dashed="true">识别成功</a-divider>
            <a-table class="sec" size="small" :rowKey="(record) => shibieResult.successList.indexOf(record)" bordered :columns="columns"
                :data-source="shibieResult.successList" :pagination="false">

                <template slot="name" slot-scope="text, record">
                    <div v-if="record.name" @click="yulanHetong(record.file_path)"  style="color: #1890ff; cursor: pointer">
                        {{ record.name || '' }}
                    </div>
                </template>

                
                <template slot="chengbaodanwei" slot-scope="text, record">
                    {{ record.orderData.chengbaodanwei || '' }}
                </template>

                <template slot="quancheng" slot-scope="text, record">
                    {{ record.orderData.tou_name || '' }}
                </template>

                <!-- <template slot="addr" slot-scope="text, record">
                    {{ record.orderData.tou_addr || '' }}
                </template> -->

                <template slot="chepai" slot-scope="text, record">
                    {{ record.orderData.chepai || '' }}
                </template>

                <template slot="chejia" slot-scope="text, record">
                    {{ record.orderData.chejia || '' }}
                </template>


                <template slot="guyuanrenshu" slot-scope="text, record">
                    {{ record.orderData.guyuanrenshu || '' }}
                </template>

                <template slot="peichangfangan" slot-scope="text, record">
                    {{ record.orderData.peichangfangan || '' }}
                </template>

                <template slot="baoxiandanhao" slot-scope="text, record">
                    {{ record.baoxiandanhao || '' }}
                </template>

                <template slot="toubaoshijian" slot-scope="text, record">
                    {{  record.orderData.create_time || '' }}
                </template>
                <template slot="action" slot-scope="text, record">
                    <a-button @click="jumpUrl('/order/info?id=' + record.orderData.id)" style="margin-left: 5px" size="small"
                        type="">
                        查看
                    </a-button>
                </template>
            </a-table>

        </a-form>
        <!-- 提交按钮 -->
        <div class="buttons">
            <a-button type="primary" @click="doSave">保存</a-button>
        </div>
    </div>
</template>

<script>
import uploadPDFDragger from '../../components/uploadPDFDragger.vue';

const columns = [

    {
        title: "文件名称",
        scopedSlots: {
            customRender: "name",
        },
    },
    // {
    //   title: "#",
    //   scopedSlots: {
    //     customRender: "xuhao",
    //   },
    // },

    {
        title: "承保单位",
        scopedSlots: {
            customRender: "chengbaodanwei",
        },
    },
    {
        title: "名称(全称)",
        scopedSlots: {
            customRender: "quancheng",
        },
    },
    // {
    //     title: "地址",
    //     scopedSlots: {
    //         customRender: "addr",
    //     },
    // },
    {
        title: "车牌",
        scopedSlots: {
            customRender: "chepai",
        },
    },
    {
        title: "车架",
        scopedSlots: {
            customRender: "chejia",
        },
    },
    {
        title: "雇员人数",
        scopedSlots: {
            customRender: "guyuanrenshu",
        },
    },
    {
        title: "方案(万)",
        scopedSlots: {
            customRender: "peichangfangan",
        },
    },
    
    {
        title: "保险单号",
        scopedSlots: {
            customRender: "baoxiandanhao",
        },
    },

    {
        title: "投保时间",
        scopedSlots: {
            customRender: "toubaoshijian",
        },
    },
    {
        title: "操作",
        scopedSlots: {
            customRender: "action",
        },
    },
];

export default {
    name: 'shibiePdf',
    components: { uploadPDFDragger },
    data() {
        return {
            columns,
            shibieResult: {
                failList: [],
                successList: []
            },
            yulanData: {
                show: false,
                url: ''
            },
            uploadKey: Date.now()
        };
    },
    async created() {
    },
    methods: {
        callbackPdf(d) {
            this.shibieResult = d
            console.log(d)
        },
        shibie() {
            console.log('开始识别...')
        },
        yulanHetong(url) {
            if(url.includes('project/')) {
                window.open(this.ossUrl + url)
            } else {
                window.open(this.ossUrl + '/project' + url)
            }
        },
        jumpUrl(url){
            this.$router.push(url)
        },
        async ceshi() {
            const r = await this.$axios.post('/file/parsePdf')
        },
        async doSave() {
            const that = this
            if(this.shibieResult.successList.length == 0) {
                this.$message.error('没有识别成功的PDF..')
                return
            }
            const params = []
            for(let item of this.shibieResult.successList) {
                params.push({
                    id: item.orderData.id,
                    pdfPath: item.file_path,
                    baoxiandanhao: item.baoxiandanhao,
                    baoandianhua: item.baoandianhua
                })
            }
            const r = await that.$axios.post('/order/updatePdfPath', {
                params: params
            })
            if(r.status == 1) {
                that.uploadKey = Date.now()
                that.shibieResult = {
                    failList: [],
                    successList: []
                }
            }
        }
    },
};
</script>
<style>
.dynamic-delete-button {
    cursor: pointer;
    position: relative;
    top: 4px;
    font-size: 24px;
    color: #999;
    transition: all 0.3s;
}

.dynamic-delete-button:hover {
    color: #777;
}

.dynamic-delete-button[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
}

.shibie-bottom {
    display: flex;
    min-width: 0;

    .left {
        flex: 1;
        min-width: 0;

    }

    .right {
        flex: 1;
        min-width: 0;

    }
}
</style>