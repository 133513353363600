<template>
    <a-upload-dragger :key="nowKey" name="file" :multiple="false" :action="route" :headers="headers"
        @change="handleChange" :show-upload-list="false" :before-upload="beforeUpload" :data="data">
        <p class="ant-upload-drag-icon">
            <a-icon type="inbox" />
        </p>
        <p class="ant-upload-text">
            点击或者拖拽文件到此区域上传
        </p>
        <!-- <p class="ant-upload-hint">
        Support for a single or bulk upload. Strictly prohibit from uploading company data or other
        band files
        </p> -->
    </a-upload-dragger>
</template>

<script>
// import * as imageConversion from 'image-conversion'
export default {
    props: [ "folder", "oldUrl", "allow", "type"],
    data() {
        return {
            route: this.API + "/file/uploadFile",
            headers: {
                token: "",
            },
            data: {},
            loading: false,
            tupianleixing: '',
            nowKey: Date.now()
        };
    },
    watch: {
        // oldFiles() {
        //     if (this.oldFiles.length > 0) {
        //         this.renderFiles();
        //     }
        // },
     
    },
    created() {
        
        this.tupianleixing = 'pdf'
        this.headers.token = this.$store.state.token;
        this.data.folder = this.folder || "other";
    },
    methods: {
        async handleChange(info) {
            const that = this

            if (["done", "removed"].includes(info.file.status)) {
                if (info.file.status == 'done' && info.file.response.status == 1) {
                    that.$emit('uploadSinglePdfCallBack', info.file.response)
                }
            }
        },
        beforeUpload(file) {
            if (file.type.indexOf('pdf') == -1) {
                this.$message.destroy()
                this.$message.error('只能上传pdf格式图片!');
                return false
            }
            const isLt10M = file.size / 1024 / 1024 < 15;
            if (!isLt10M) {
                this.$message.destroy()
                this.$message.error('图片大小不能超过15MB!');
                return false
            }
            return true
        }
    },
};
</script>

<style lang="scss" scoped>
.ant-upload-text {
    font-size: 14px;
}
</style>