var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"component-wrap"},[_c('a-form',{staticClass:"form sec",attrs:{"form":_vm.form,"label-col":{ span: 10 },"wrapper-col":{ span: 10 }}},[_c('a-form-item',{attrs:{"label":"营业执照"}},[_c('UploadPic',{attrs:{"folder":'yingyezhizhao',"type":'yingyezhizhao',"oldUrl":_vm.yyzzUrl},on:{"callbackPic":_vm.yyzzCallback}})],1),_c('a-form-item',{attrs:{"label":"名称(全称)"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['tou_name', {
        rules: [
            {
                required: true,
                message: '请输入名称(全称)'
            }]
    }]),expression:"['tou_name', {\n        rules: [\n            {\n                required: true,\n                message: '请输入名称(全称)'\n            }]\n    }]"}],staticStyle:{"width":"60%"},attrs:{"placeholder":"请输入名称(全称)"}})],1),_c('a-form-item',{attrs:{"label":"社会信用代码"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['tou_sn', {
        rules: [
            {
                required: true,
                message: '请输入社会信用代码'
            }]
    }]),expression:"['tou_sn', {\n        rules: [\n            {\n                required: true,\n                message: '请输入社会信用代码'\n            }]\n    }]"}],staticStyle:{"width":"60%"},attrs:{"placeholder":"请输入社会信用代码"}})],1),_c('a-form-item',{attrs:{"label":"所在城市"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['tou_city', {
        rules: [
            {
                required: true,
                message: '请输入所在城市'
            }]
    }]),expression:"['tou_city', {\n        rules: [\n            {\n                required: true,\n                message: '请输入所在城市'\n            }]\n    }]"}],staticStyle:{"width":"60%"},attrs:{"placeholder":"请输入所在城市"}})],1),_c('a-form-item',{attrs:{"label":"联系地址"}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:(['tou_addr']),expression:"['tou_addr']"}],staticStyle:{"width":"60%"},attrs:{"placeholder":"请输入联系地址"}})],1)],1),_c('div',{staticClass:"buttons"},[_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.sub}},[_vm._v("提交")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }